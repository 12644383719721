export const plans = [
    { name: 'Bronze', price: 20, image: '/images/dashboard/bronze.png' },
    { name: 'Silver', price: 50, image: '/images/dashboard/silver.png', recommended: true },
    { name: 'Gold', price: 100, image: '/images/dashboard/gold.png' },
    { name: 'Platinum', price: 1000, image: '/images/dashboard/platinum.png' },
];

export const PAYMENT_METHODS = [
    {
        name: 'Credit or Debit card',
        icon: '/images/payment/visa.png'
    },
    {
        name: 'JazzCash',
        icon: '/images/payment/jazz.png'
    },
    {
        name: 'Easypaisa',
        icon: '/images/payment/easypaisa.png'
    }
];
export const planDetailsFields = [
    {
        icon: '/images/dashboard/users-2.png',
        alt: 'Eligible age groups',
        title: 'Eligible age groups:',
        description: 'People in the age group of 18 to 55 are eligible for the cover (20 lacs for age 18 to 45, 10 lacs for age 45-55).'
    },
    {
        icon: '/images/dashboard/timer.png',
        alt: 'Waiting Period',
        title: 'Waiting Period:',
        description: 'You will be eligible for the cover after 3 months of waiting period. Missing the payment will reset the waiting period.'
    },
    {
        icon: '/images/dashboard/helping-hand.png',
        alt: 'Support for key cancers',
        title: 'Support for key cancers:',
        description: 'Coverage for Breast, Brain, Liver, Lungs & Cervix Uteri cancers.'
    },
    {
        icon: '/images/dashboard/stethoscope.png',
        alt: 'Access to leading hospitals',
        title: 'Access to leading hospitals:',
        description: 'We have partnered with the renowned cancer treatment hospitals in Pakistan.'
    }
];