import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, Box, TableRow, Paper, Tooltip, IconButton, Chip, TableFooter, TablePagination, TextField, InputAdornment, CircularProgress, Typography } from '@mui/material';
import { Lock, LockOpen, Search as SearchIcon } from '@mui/icons-material';
import './Users.scss';
import { useToast } from '../../../components/ToastNotification/ToastNotification';
import { useAppDispatch } from '../../../store';
import { getUsers, lockUserAccount, blockUserAccount, deleteUserForAdmin } from '../../../features/api.slice';
import ConfirmationDialog from '../../../components/DialogBox/Confirmation';
import SpinnerPage from '../../../components/Spinner/Spinner';
import BlockIcon from '@mui/icons-material/Block';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';  // Pencil icon
import { Backdrop, useMediaQuery } from '@mui/material';
import EditUser from './Popup/UserEdit';
import DeleteIcon from '@mui/icons-material/Delete';  // Trash can

function Users() {
  const [users, setUsers] = useState([]);
  const [totalItems, setTotalItems] = useState(0); // Total items count
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default items per page
  const [openDialog, setOpenDialog] = useState(false);
  const [openBlockDialog, setOpenBlockDialog] = useState(false);
  const [userAccountData, setUserAccountData] = useState({});
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0); // Current page
  const showToast = useToast();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTableData, setIsLoadingTableData] = useState(true);
  const [phoneNumberForSearch, setPhoneNumberForSearch] = useState('');
  useEffect(() => {
    fetchUsers(); // Function to handle fetching users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]); // Re-fetch users when these dependencies change

  // Fetch users function with search parameter
  const fetchUsers = () => {
    const payload = {
      limit: rowsPerPage,
      page: page + 1,
      /*  setUsers, */
      setTotalItems,
      phoneNumber: phoneNumberForSearch,
      setUsers: (data) => {
        setUsers(data);
        setIsLoadingTableData(false);
      }
    };
    dispatch(getUsers(payload));
  };
  function accountHandle(row, type) {
    setUserAccountData(row);
    if (type === 'blocked') {
      setOpenBlockDialog(true);
    } else if (type === 'locked') {
      setOpenDialog(true);
    }
    if (type === 'edit') {
      setOpenEditPopup(true);
    }
    if (type === 'delete') {
      setOpenDeleteDialog(true);
    }
  }

  function handleClose() {
    setOpenDialog(false);
    setOpenBlockDialog(false);
  }
  function handleCloseUserEditPopup() {
    setOpenEditPopup(false);
    fetchUsers();
  }
  function handleUserAccountLock() {
    setIsLoading(true);
    const payload = {
      id: userAccountData.id,
      limit: rowsPerPage,
      setUsers,
      setTotalItems,
      setIsLoading,
      page: page + 1,
      showToast,
      phoneNumber: phoneNumberForSearch,
    };
    dispatch(lockUserAccount(payload));
    setOpenDialog(false);
  }

  function handleUserAccountBlock() {
    setIsLoading(true);
    const payload = {
      id: userAccountData.id,
      limit: rowsPerPage,
      setUsers,
      setTotalItems,
      setIsLoading,
      page: page + 1,
      showToast,
      phoneNumber: phoneNumberForSearch,
    };
    dispatch(blockUserAccount(payload));
    setOpenBlockDialog(false);
  }

  const handleChangePage = (event, newPage) => {
    setIsLoadingTableData(true);
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing rows per page
  };

  const handleSearchChange = (event) => {
    setPhoneNumberForSearch(event.target.value);
    if (event.key === 'Enter') {
      const payload = {
        limit: rowsPerPage,
        page: 1,
        phoneNumber: event.target.value,
        setUsers,
        setTotalItems,
      }
      dispatch(getUsers(payload));
    }
  };
  function handleUserAccountDelete() {
    const payload = {
      userID: userAccountData.id,
      showToast,
      setOpenDeleteDialog,
      fetchUsers
    };
    dispatch(deleteUserForAdmin(payload));
  }
  const renderStatusPill = (blocked, deleted) => {
    if (!deleted) {
      return blocked === 0 ? (
        <Chip label="Active" color="success" size="small" className="status-pill" />
      ) : (
        <Chip label="Blocked" color="error" size="small" className="status-pill" />
      );
    } else {
      return (
        <Chip label="Deleted" color="error" size="small" className="status-pill" />
      );
    }
  };
  const isMobile = useMediaQuery('(max-width:600px)');
  function dateFormatter(date) {
    if (date === null || date === '') return '';
    const newDate = new Date(date);
    return newDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).split(' ').join('-');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
      }}
    >
      <div className="policy-header align-center">
        <h2 className="header-title">Users</h2>
        <Box sx={{ width: '30%', alignContent: 'center' }}>
          <TextField
            variant="outlined"
            placeholder="Search by phone number"
            onKeyDown={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </div>
      <TableContainer
        component={Paper}
        className="table-container"
        sx={{
          minHeight: 'calc(100vh - 250px)',  // Fixed height for the table
          maxHeight: 'calc(100vh - 250px)',  // Maximum height for the table
          overflowX: 'auto',   // Enable horizontal scrolling
          overflowY: 'auto',   // Enable vertical scrolling
        }}
      >
        <Table stickyHeader>
          <TableHead className="user-table-header">
            <TableRow className="table-cell text-center">
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>CNIC</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Account Status</TableCell>
              <TableCell>Plan Subscribed Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingTableData && (
              <TableRow>
                <TableCell rowSpan={rowsPerPage} colSpan={9} style={{ textAlign: 'center' }}>
                  <CircularProgress sx={{ color: '#E44E80' }} />
                  <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                    Users are loading...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {users.length === 0 && !isLoadingTableData && (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                  <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                    No users found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoadingTableData &&
              users.map((row) => (
                <TableRow key={row.id} className="table-row table-cell">
                  <TableCell>{row.first_name}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.cnic}</TableCell>
                  <TableCell>{dateFormatter(row.dob)}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{dateFormatter(row.created_at)}</TableCell>
                  <TableCell className="text-center">{renderStatusPill(row.blocked, row.deleted)}</TableCell>
                  <TableCell>{row.planSubscribedDate}</TableCell>
                  <TableCell>
                    {!row.admin && !row.deleted && (
                      <>
                        <Tooltip title="Edit User">
                          <IconButton onClick={() => accountHandle(row, 'edit')}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={row.blocked ? "Unblock Account" : "Block Account"}>
                          <IconButton onClick={() => accountHandle(row, 'blocked')}>
                            {row.blocked ? <LockOpenIcon /> : <BlockIcon />}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={row.locked ? "Unlock Account" : "Lock Account"}>
                          <IconButton onClick={() => accountHandle(row, 'locked')}>
                            {row.locked ? <Lock /> : <LockOpen />}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete User">
                          <IconButton onClick={() => accountHandle(row, 'delete')}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white', // Keeps pagination background visible
          zIndex: 1,                 // Ensures it stays on top when scrolling
        }}
      >
        <TableRow>
          <TablePagination
            colSpan={5}
            rowsPerPageOptions={[5, 10, 15, 20]}
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableRow>
      </TableFooter>
      <ConfirmationDialog
        title={`${userAccountData?.locked ? 'Unlock' : 'Lock'} Account`}
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleUserAccountLock}
        message={`Are you sure you want to ${userAccountData?.locked ? 'unlock' : 'lock'} this account?`}
      />
      <ConfirmationDialog
        title={`${userAccountData?.blocked ? 'Unblock' : 'Block'} Account`}
        open={openBlockDialog}
        onClose={handleClose}
        onConfirm={handleUserAccountBlock}
        message={`Are you sure you want to ${userAccountData?.blocked ? 'unblock' : 'block'} this account?`}
      />
      <ConfirmationDialog
        title={`Delete Account`}
        open={openDeleteDialog}
        onClose={handleClose}
        onConfirm={handleUserAccountDelete}
        message={`Are you sure you want to delete this account?`}
      />
      {openEditPopup && (
        <Backdrop
          open={openEditPopup}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1200,
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
          }}
        >
          <EditUser
            EditUserData={userAccountData}
            wrapperClass={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              ...(isMobile
                ? { width: '80%', height: 'auto', padding: '16px' }
                : { height: '100%', width: '100%' }),
            }}
            onContinue={handleCloseUserEditPopup}
          />
        </Backdrop>
      )}
      <SpinnerPage loading={isLoading} />
    </Box>
  );
}

export default Users;
