import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Switch,
    FormControlLabel,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TableFooter,
    TablePagination,
    CircularProgress,
    Typography,
} from '@mui/material';
import { useAppDispatch } from '../../../../store';
import { deleteReferralsData, getReferralsData, setReferralCode, updateReferralsData } from '../../../../features/api.slice';
import { useToast } from '../../../../components/ToastNotification/ToastNotification'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../../components/DialogBox/Confirmation';
const ReferralForm = () => {
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState(1); // State for referral status
    const [isLoading, setIsLoading] = useState(false); // State for loading status
    const [referralsData, setReferralsData] = useState([]); // State for referral data
    const [referralID, setReferralID] = useState(); // State for referral ID
    const dispatch = useAppDispatch();
    const [refreshTable, setRefreshTable] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [totalItems, setTotalItems] = useState(0);
    // For editing announcements
    const handleEditReferral = (referral) => {
        // Populate form fields with selected announcement data for editing
        setCode(referral.code);
        setDescription(referral.description);
        setStatus(referral.status);
        setReferralID(referral.id);
    };
    const showToast = useToast();
    // Function to handle the status switch
    const handleStatusChange = (event) => {
        setStatus(event.target.checked);
    };

    // Function to handle form submission
    function handleSubmit(e) {
        setIsLoading(true); // Set loading state to true
        e.preventDefault(); // Prevent default form behavior
        if (!referralID) {
            const payload = {
                code: code,
                description: description,
                status: status ? 1 : 0, // Pass the current status value
                showToast,
                setIsLoading,
                setReferralsData,
                setTotalItems,
            };
            dispatch(setReferralCode(payload)); // Dispatch the action to set the referral code
        } else if (referralID) {
            const payload = {
                code: code,
                description: description,
                status: status ? 1 : 0, // Pass the current status value
                showToast,
                referralID,
                setIsLoading,
                setReferralsData,
            };
            dispatch(updateReferralsData(payload));
        }
        setDescription('')
        setCode('')
        setStatus(1)
        setReferralID()
        setRefreshTable(!refreshTable)
    }
    // Detect screen size for responsiveness
    const [rowsPerPage, setRowsPerPage] = useState(10); //
    const [page, setPage] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    useEffect(() => {
        const payload = {
            limit: rowsPerPage,
            page: page + 1,
            setReferralsData,
        };
        dispatch(getReferralsData(payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // reset to the first page when changing rows per page
    };
    const renderStatusPill = (status) => {
        return status === 1 ? (
            <Chip label="Active" color="success" size="small" className="status-pill" />
        ) : (
            <Chip label="In Active" color="error" size="small" className="status-pill" />
        );
    };
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    function DeletePopup(id) {
        setReferralID(id)
        setOpenConfirmationDialog(true);
    }
    function DeleteReferral() {
        const payload = {
            referralID,
            showToast,
            setIsLoading,
            setReferralsData,
            setReferralID,
            setOpenConfirmationDialog
        }
        dispatch(deleteReferralsData(payload))
    }
    function handleClose() {
        setOpenConfirmationDialog(false);
        setReferralID()
    }
    return (
        <>
            <Paper elevation={1} sx={{ padding: 5 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                label="Referral Code"
                                fullWidth
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                required
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={9}>
                            <TextField
                                label="Remarks"
                                fullWidth
                                multiline
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!!status}
                                        onChange={handleStatusChange}
                                        color="primary"
                                    />
                                }
                                label="Status"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} className="button-container">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="save-button"
                            disabled={isLoading}
                            sx={{
                                padding: '10px 0',
                                fontWeight: 'bold',
                                borderRadius: '8px',
                                backgroundColor: '#E44E80',
                            }}
                        >
                            {isLoading ? 'Creating...' : 'Create'}
                        </Button>
                    </Grid>
                </form>
            </Paper>
            <TableContainer component={Paper} className="table-container">
                <Table>
                    <TableHead className="table-header">
                        <TableRow className='table-cell'>
                            <TableCell>Referral Code</TableCell>
                            <TableCell>Remarks</TableCell>
                            <TableCell className='text-center'>Status</TableCell>
                            <TableCell className='text-center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='overflow-scroll'>
                        {
                            referralsData?.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <CircularProgress sx={{ color: '#E44E80' }} />
                                        <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                                            Loading Referrals...
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        {
                            referralsData?.response?.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <Typography variant="body1">No referrals available.</Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        {referralsData?.response?.map((row) => (
                            <TableRow key={row.id} className="table-row table-cell">
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell className='text-center'>{renderStatusPill(row.status)}</TableCell>
                                <TableCell>
                                    <div className="mobile-actions">
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleEditReferral(row)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => DeletePopup(row.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={5}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                count={totalItems}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleRowsPerPageChange}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <ConfirmationDialog
                title="Delete Hospital Data"
                open={openConfirmationDialog}
                onClose={handleClose}
                onConfirm={DeleteReferral}
                message="Are you sure you want to delete this hospital data?"
            />
        </>
    );
};

export default ReferralForm;
