import React, { useState } from 'react';
import '../OTP/otp.scss';
import TitleAndLogo from '../../TitleAndLogo/titleAndLogo';
import SingleDigitInputs from '../SingleDigitInput/singleDigitInput';
import { useAppDispatch } from '../../../store';
import { changePincode } from "../../../features/api.slice";
import { useToast } from '../../ToastNotification/ToastNotification';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';  // Import visibility icon
import VisibilityOff from '@mui/icons-material/VisibilityOff';
function ChangePincodeModal({ onContinue, wrapperClass }) {
    const [oldOTP, setOldOTP] = useState('')
    const [newOtp, setNewOtp] = useState('')
    const [confirmNewOtp, setConfirmNewOtp] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false); // Added state for toggling visibility

    const dispatch = useAppDispatch()
    const showToast = useToast()
    function handleOldOtpChange(e) {
        setOldOTP(e)
    }
    function handleNewOtpChange(e) {
        setNewOtp(e)
    }
    function handleConfirmNewOtpChange(e) {
        setConfirmNewOtp(e)
    }
    function verifyOtp() {
        if (newOtp !== confirmNewOtp) {
            showToast('New Pincode does not match', 3000)
            return
        }
        setIsLoading(true)
        const payload = {
            old_password: oldOTP,
            password: newOtp,
            password_confirmation: confirmNewOtp,
            onContinue,
            showToast,
            setIsLoading,
        }
        dispatch(changePincode(payload))
    }
    function onClose() {
        onContinue()
    }
    return (
        <div style={wrapperClass} className={wrapperClass ? wrapperClass : "otp-modal-wrapper"}>
            <div className="otp-modal">
                <div className="title-logo-container">
                    <TitleAndLogo logoColor="pink" />
                    <IconButton
                        className="close-button"
                        onClick={onClose} // Call the onClose function
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>

                <h2>Enter Old Pincode</h2>
                <p className='d-flex justify-space-between'>
                    Please enter your old pin
                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                        {!showPassword ? <VisibilityOff fontSize='small' style={{ cursor: 'pointer' }} /> : <Visibility fontSize='small' style={{ cursor: 'pointer' }} />}
                    </span>
                </p>
                <SingleDigitInputs onChange={(e) => { handleOldOtpChange(e) }} showPassword={showPassword} />
                <h2>Set new pincode</h2>
                <p className='d-flex justify-space-between'>Set a new 4-digit pincode
                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                        {!showPassword ? <VisibilityOff fontSize='small' style={{ cursor: 'pointer' }} /> : <Visibility fontSize='small' style={{ cursor: 'pointer' }} />}
                    </span>
                </p>
                <SingleDigitInputs onChange={(e) => { handleNewOtpChange(e) }} showPassword={showPassword} />

                <h2>Confirm new pincode</h2>
                <p className='d-flex justify-space-between'>
                    Re-enter the pincode
                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                        {!showPassword ? <VisibilityOff fontSize='small' style={{ cursor: 'pointer' }} /> : <Visibility fontSize='small' style={{ cursor: 'pointer' }} />}
                    </span>
                </p>
                <SingleDigitInputs onChange={(e) => { handleConfirmNewOtpChange(e) }} showPassword={showPassword} />

                <button className={isLoading ? 'disabled-button continue-button' : 'continue-button'} disabled={isLoading} onClick={verifyOtp}>{isLoading ? 'Updating...' : 'Update '}</button>
            </div>
        </div>
    );
};

export default ChangePincodeModal;
