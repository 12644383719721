import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    useMediaQuery,
    Typography,
    CircularProgress,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './Announcements.scss';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { deleteAnnouncementsData, getAnnouncementsData, GetPlans, setAnnouncementsData, updateAnnouncementsData } from '../../../../features/api.slice';
import dayjs from 'dayjs';
import { allPlansData } from '../../../../features/api.selector';
import { useToast } from '../../../../components/ToastNotification/ToastNotification';
import {

} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../../components/DialogBox/Confirmation';
const AnnouncementsForm = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [fileName, setFileName] = useState("");
    function getUserPlanName(id) {
        const plan = planData?.find((plan) => plan.policyId === id);
        return plan?.title || 'N/A';
    }
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedPolicy, setSelectedPolicy] = useState('');
    const [document, setDocument] = useState(null);
    const [announcements, setAnnouncements] = useState([]);
    const [announcementID, setAnnouncementID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const showToast = useToast();
    const dispatch = useAppDispatch();
    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        // Validate form data
        if (!title || !description || !startDate || !endDate || !selectedPolicy) {
            return;
        }
        if (endDate.isBefore(startDate)) {
            return;
        }
        if (!announcementID) {
            const payload = {
                title,
                description,
                startDate: dayjs(startDate).format('YYYY-MM-DD'),
                endDate: dayjs(endDate).format('YYYY-MM-DD'),
                selectedPolicy,
                document, // Ensure the document is part of the payload
                setAnnouncements,
                setIsLoading,
                showToast
            };
            dispatch(setAnnouncementsData(payload));
        } else if (announcementID) {
            const payload = {
                title,
                description,
                startDate: dayjs(startDate).format('YYYY-MM-DD'),
                endDate: dayjs(endDate).format('YYYY-MM-DD'),
                selectedPolicy,
                document, // Ensure the document is part of the payload
                announcementID,
                setAnnouncements,
                setIsLoading,
                showToast
            };
            dispatch(updateAnnouncementsData(payload));
        }
        // Reset form
        setTitle('');
        setDescription('');
        setStartDate(null);
        setEndDate(null);
        setSelectedPolicy('');
        setDocument(null);
        setFileName('');
    };
    const handleDocumentChange = (e) => {
        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
            setDocument(e.target.files[0]);
        }
    };
    const handleEditAnnouncement = (announcement) => {
        // Populate form fields with selected announcement data for editing
        setTitle(announcement.title);
        setDescription(announcement.description);
        setStartDate(dayjs(announcement.start_date));
        setEndDate(dayjs(announcement.end_date));
        setSelectedPolicy(announcement.policy_id);
        setAnnouncementID(announcement.id);
    };
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    // Function to delete an announcement
    function DeletePopup(id) {
        setAnnouncementID(id)
        setOpenConfirmationDialog(true);
    }
    function handleClose() {
        setOpenConfirmationDialog(false);
    }
    function DeleteReferral() {
        const payload = {
            announcementID,
            setAnnouncements,
            setIsLoading,
            showToast,
            setOpenConfirmationDialog,
        };
        dispatch(deleteAnnouncementsData(payload));
        setAnnouncementID()
    }

    useEffect(() => {
        dispatch(GetPlans());
        const payload = {
            setAnnouncements
        }
        dispatch(getAnnouncementsData(payload))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const allPlans = useAppSelector(allPlansData)
    const planData = (allPlans?.body?.response?.map((item) => { return { title: item.title, policyId: item.id } }))
    return (
        <>
            <Paper elevation={1} sx={{ padding: 5 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                label="Title"
                                fullWidth
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={9}>
                            <TextField
                                label="Description"
                                fullWidth
                                multiline
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    fullWidth
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth required sx={{ mt: 0 }} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    fullWidth
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth required sx={{ mt: 0 }} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth required sx={{ mt: 0 }}>
                                <InputLabel id="user-select-label">Select Policy</InputLabel>
                                <Select
                                    labelId="user-select-label"
                                    value={selectedPolicy}
                                    onChange={(e) => setSelectedPolicy(e.target.value)}
                                >
                                    {planData?.map((item) => (
                                        <MenuItem key={item?.policyId} value={item?.policyId}>
                                            {item?.title}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{ marginTop: '10px' }}
                                    className='upload-button'
                                >
                                    Upload Document
                                    <input
                                        type="file"
                                        accept="*"
                                        name="logo"
                                        hidden
                                        onChange={handleDocumentChange}
                                    />
                                </Button>
                                {fileName && (
                                    <span style={{ marginLeft: '10px' }}>{fileName}</span>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} className='button-container'>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className='save-button'
                            disabled={isLoading}
                            sx={{
                                padding: '10px 0',
                                fontWeight: 'bold',
                                borderRadius: '8px',
                                backgroundColor: '#E44E80',
                            }}
                        >
                            {announcementID && isLoading ? 'Updating...' : announcementID && !isLoading ? 'Update' : !announcementID && isLoading ? 'Creating...' : 'Create'}
                        </Button>
                    </Grid>
                </form>
            </Paper>
            <Paper elevation={1} sx={{ padding: 5 }}>
                <TableContainer component={Paper} className='height--40'>
                    {!isMobile ? (
                        <Table>
                            <TableHead className="table-header ">
                                <TableRow>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>End Date</TableCell>
                                    <TableCell>User Group</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="overflow-scroll">
                                {
                                    announcements?.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                <CircularProgress sx={{ color: '#E44E80' }} />
                                                <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                                                    Loading Announcements...
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    announcements?.response?.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                <Typography variant="body1">No announcements available.</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {announcements?.response?.map((announcement, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{announcement.title}</TableCell>
                                        <TableCell>{announcement.description}</TableCell>
                                        <TableCell>{announcement.start_date}</TableCell>
                                        <TableCell>{announcement.end_date}</TableCell>
                                        <TableCell>{getUserPlanName(announcement.policy_id)}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEditAnnouncement(announcement)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => DeletePopup(announcement.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        // For mobile, display items in a card-like format

                        announcements?.response?.map((announcement, index) => (
                            <Paper className="mobile-card" key={index}>
                                <Typography variant="h6">
                                    {announcement.title}
                                </Typography>
                                <Typography>
                                    <strong>Description:</strong> {announcement.description}
                                </Typography>
                                <Typography>
                                    <strong>Start Date:</strong> {announcement.start_date}
                                </Typography>
                                <Typography>
                                    <strong>End Date:</strong> {announcement.end_date}
                                </Typography>
                                <Typography>
                                    <strong>User Group:</strong> {announcement.selectedUser}
                                </Typography>
                                <div className="mobile-actions">
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleEditAnnouncement(announcement)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => DeletePopup(announcement.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </Paper>
                        ))

                    )}
                </TableContainer>
            </Paper>
            <ConfirmationDialog
                title="Delete Announcement"
                open={openConfirmationDialog}
                onClose={handleClose}
                onConfirm={DeleteReferral}
                message="Are you sure you want to delete this Announcement?"
            />
        </>
    );
};

export default AnnouncementsForm;
