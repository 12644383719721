import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getPlanPricingData, GetPlans } from "../../../features/api.slice";
import { allPlansData } from "../../../features/api.selector";
import Box from '@mui/material/Box';
import './RecurringPayments.scss';
import { Backdrop, useMediaQuery, CircularProgress } from "@mui/material";
import AddRecurringPayment from "./RecurringPaymentPopup/RecurringPaymentPopup";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import formatDate from './../../../utils/global';

const RecurringPayments = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [showScrollbar, setShowScrollbar] = useState(false);
    const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
    const [recurringPayments, setRecurringPayments] = useState([]);
    const [loading, setLoading] = useState(true); // <-- Add loading state
    const dispatch = useAppDispatch();
    const [isLoadingTableData, setIsLoadingTableData] = useState(true);

    // Fetch plans when the component mounts
    useEffect(() => {
        dispatch(GetPlans());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            const payload = {
                /* setRecurringPayments, */
                policyID: activeTab,
                setRecurringPayments: (data) => {
                    setRecurringPayments(data);
                    setIsLoadingTableData(false);
                }
            };
            await dispatch(getPlanPricingData(payload));
            setLoading(false); // End loading once data is fetched
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    // Get all plans from the Redux store
    const plans = useAppSelector(allPlansData);
    const isMobile = useMediaQuery('(max-width:600px)');

    // Filter active plans
    const selectedPlanData = plans?.body?.response?.filter(item => item.status === 'active');
    const selectedPlan = selectedPlanData?.find(plan => plan.id === activeTab);

    // Function to handle tab click and show scrollbar temporarily
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        setShowScrollbar(true);
        setRecurringPayments([]);
        setIsLoadingTableData(true);
        setTimeout(() => setShowScrollbar(false), 1000);
    };

    function handleDeduction(coverage) {
        setOpenPaymentPopup(true);
    }

    // Handle closing popup
    const closeCoveragePopup = () => {
        setOpenPaymentPopup(false);
    };

    function loadNewDatainTable() {
        const payload = {
            setRecurringPayments,
            policyID: activeTab,
        };
        dispatch(getPlanPricingData(payload));
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: '20px',
                backgroundColor: '#f5f5f5',
                minHeight: '100vh',
            }}
        >
            <div>
                <div className="policy-header">
                    <h2 className="header-title">Recurring Payments</h2>
                </div>
                <div className={`tab-menu ${showScrollbar ? 'show-scrollbar' : ''}`}>
                    {selectedPlanData?.map((tab) => (
                        <button
                            key={tab.id}
                            className={activeTab === tab?.id ? 'active' : ''}
                            onClick={() => handleTabClick(tab?.id)}
                        >
                            <div className="tab-content">
                                <span>{tab?.title}</span>
                            </div>
                        </button>
                    ))}
                </div>
                <div className="button-group justify-end">
                    <button className="policy-button" onClick={handleDeduction}>Add payment</button>
                </div>
            </div>

            {/* Table to display recurring payments */}
            <TableContainer component={Paper}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                        <CircularProgress /> {/* Loading spinner */}
                    </Box>
                ) : (
                    <Table>
                        <TableHead className="recurring-table-header">
                            <TableRow>
                                <TableCell>Plan Name</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Deduction Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="overflow-scroll">
                            {isLoadingTableData && (
                                <TableRow>
                                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                        <CircularProgress sx={{ color: '#E44E80' }} />
                                        <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                                            Loading Payments...
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {
                                recurringPayments?.response?.length === 0 && !isLoadingTableData && (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">
                                            <Typography variant="body1">No recurring payments available.</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            {
                                recurringPayments?.response?.map((recPayment, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{recPayment.title}</TableCell>
                                        <TableCell>{recPayment.price}</TableCell>
                                        <TableCell>{formatDate(recPayment.deduction_date)}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                )}
            </TableContainer>

            {openPaymentPopup && (
                <Backdrop
                    open={openPaymentPopup}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                >
                    <AddRecurringPayment
                        plans={selectedPlan}
                        wrapperClass={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            ...(isMobile
                                ? { width: '80%', height: 'auto', padding: '16px' }
                                : { height: '100%', width: '100%' }),
                        }}
                        activeTab={activeTab}
                        loadNewDatainTable={loadNewDatainTable}
                        onContinue={closeCoveragePopup}
                    />
                </Backdrop>
            )}
        </Box>
    );
};

export default RecurringPayments;
