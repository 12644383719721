import React, { useState, useEffect,useContext } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import './form.scss';
import TitleAndLogo from '../../TitleAndLogo/titleAndLogo';
import { useAppDispatch } from '../../../store';
import { useToast } from '../../ToastNotification/ToastNotification';
import { callValidateUser } from "../../../features/api.slice";
import NewsPopup from '../../NewsPopup/NewsPopup';
import AdminContext from '../../../contexts/AdminConfigs';

const Form = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [isPakistaniNumber, setIsPakistaniNumber] = useState(true); // State to track whether the number is Pakistani
  const [showPopup, setShowPopup] = useState(true);
  const showToast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { adminConfigs } = useContext(AdminContext);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePhoneChange = (value, country) => {
    setMobileNumber(value);
    setIsPakistaniNumber(country.dialCode === '92'); // Check if the selected country code is +92 for Pakistan
  };
  function validateMobileNumber(number) {
    // Pakistani mobile number pattern: +92 followed by 10 digits starting with 3
    const pattern = /^(\92|0092)?3\d{9}$|^03\d{9}$/;
    return pattern.test(number);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!mobileNumber) {
      return showToast('Please enter mobile number.', 3000);
    }
    if (!isPakistaniNumber) {
      return showToast('Thank you for your interest in ITTEHAAD, we are coming to your country very soon', 100000000,true);
    }
    if (!validateMobileNumber(mobileNumber)) {
      return showToast('Please enter correct phone number', 3000);
    }

    setIsLoading(true);
    const payload = {
      grant_type: "",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      phone: mobileNumber, // Mobile number with country code
      password: "",
      code: '',
      validate: 1,
      referral_code: referralCode,
      navigate,
      showToast,
      setIsLoading,
    };
    dispatch(callValidateUser(payload));
  };
 
  return (
    <div className="card">
      {!isMobile && <TitleAndLogo logoColor="pink" />}
      <h2>Enter your mobile number to get started</h2>
      <div className="form-content">
        <label className="form-label" htmlFor="mobileNumber">Mobile number</label>
        <div className="input-wrapper">
          <PhoneInput
            country={'pk'} // Set default country code
            value={mobileNumber}
            onChange={handlePhoneChange} // Update phone number and country code
            inputClass="input-field"
            containerClass="phone-input-container"
            inputProps={{
              name: 'mobileNumber',
              required: true,
              autoFocus: true,
              placeholder:'+92320-1234567'
            }}
          />
        </div>
        <span className="info-text">
          If you're referred by an ambassador or partner organization, please enter the referral code below.
        </span>
        <div className="align-at-ends">
          <label className="form-label" htmlFor="referralCode">Referral code</label>
          <div className="optional">Optional</div>
        </div>
        <div className="input-wrapper">
          <input
            type="text"
            id="referralCode"
            className="input-field"
            placeholder="Enter referral code (if any)"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className={isLoading ? 'disabled-button submit-button' : "submit-button"}
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? 'Loading....' : 'Continue'}
        </button>
      </div>
      {showPopup && adminConfigs?.generalAuthSignupPopup && <NewsPopup newsText={adminConfigs?.generalAuthSignupContent} onClose={() => setShowPopup(false)} LoginButton/>}
    </div>
  );
};

export default Form;
