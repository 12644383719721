import { call, put, takeEvery, delay } from "redux-saga/effects";
import { submitJazzCashForm } from "../utils/paymentForm";
import * as api from "../features/api.slice";
import * as apiUrl from "../services/api";

function* checkExpiryTime(action) {
  const { navigate, showToast } = action;

  // Initial check
  const expiryTime = localStorage.getItem('expiryTime'); // Get expiry time from local storage
  if (expiryTime && Date.now() >= expiryTime) {
    navigate('/');
    localStorage.clear();
    yield call(api.clearStates);
    showToast('Session Expired. Please Login again', 3000);
  }
  // Continuously check every minute
  while (true) {
    yield delay(60000); // Wait for 1 minute
    const newExpiryTime = localStorage.getItem('expiryTime'); // Get expiry time again
    if (newExpiryTime && Date.now() >= newExpiryTime) {
      navigate('/');
      localStorage.clear();
      yield call(api.clearStates);
      showToast('Session Expired. Please Login again', 3000);
    }
  }
}

function* LoginSaga(action) {
  const { navigate, showToast, setIsLoading, setIsLoggedIn, ...payload } = action.payload;
  const response = yield call(apiUrl.LoginUserApi, payload);
  yield put(api.loginData(response));
  if (response.status_code === 200) {
    localStorage.setItem('token', response?.body?.access_token);  // Store token after login
    localStorage.setItem('refreshToken', response?.body?.refresh_token);
    const expiryTime = Date.now() + (response?.body?.expires_in * 1000); // Assuming expires_in is in seconds
    localStorage.setItem('expiryTime', expiryTime); // Store expiry time
    yield delay(500);
    yield call(getUserProfileSaga, { showToast, setIsLoading });
    yield delay(300);
    if (response?.body?.user?.first_name === '') {
      navigate('/profile');
    }
    else if (response?.body?.user?.first_name !== '') {
      navigate('/dashboard');
    }
    setIsLoading(false);
    showToast('Successfully Logged In', 3000);
    yield call(checkExpiryTime, { navigate, showToast });
    setIsLoggedIn(true);
  }
  if (response.status_code === 400) {
    navigate('/modal', { state: { signUpData: response, payload: payload } });
    setIsLoading(false);
  }
  if (response.status_code === 403) {
    showToast(response?.body?.message?.phone, 3000);
  }
  if (response.error) {
    showToast(response.message, 3000);
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}
function* validateUserSaga(action) {
  const { navigate, showToast, setIsLoading, ...payload } = action.payload;
  const response = yield call(apiUrl.validateUserApi, payload);
  yield put(api.signUpData(response));
  if (response.status_code === 200 || response.status_code === 400) {
    navigate('/modal', { state: { signUpData: response, payload: payload } });
    setIsLoading(false);
  } else {
    showToast(response?.body?.message?.phone, 3000);
    setIsLoading(false);
  }
}
function* SignUpSaga(action) {
  const { navigate, showToast, setIsLoading, ...payload } = action.payload;
  const response = yield call(apiUrl.SignUpUserApi, payload);
  yield put(api.signUpData(response));
  if (response.status_code === 200) {
    if (response?.body?.user?.first_name === '') {
      navigate('/profile');
    }
    else if (response?.body?.user?.first_name !== '') {
      navigate('/dashboard');
    }
    showToast('Successfully Signed Up and logged In', 3000);
    localStorage.setItem('token', response?.body?.access_token);  // Store token after login
    localStorage.setItem('refreshToken', response?.body?.refresh_token);
    yield delay(500);
    yield call(getUserProfileSaga, { showToast, setIsLoading });
    setIsLoading(false);
  } if (response.status_code === 400) {
    navigate('/modal', { state: { signUpData: response, payload: payload } });
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}
function* updateUserProfileSaga(action) {
  const { navigate, showToast, setIsLoading, ...payload } = action.payload;
  const response = yield call(apiUrl.updateUserProfile, payload);
  if (response.status_code === 200) {
    showToast('Profile Updated Successfully', 3000);
    yield delay(500);
    setIsLoading(false);
    yield call(getUserProfileSaga, { showToast, setIsLoading });
    navigate('/dashboard')
  }
  else if (response.status_code !== 200) {
    showToast(response?.body?.message, 3000);
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}
function* GetAppPlansSaga(action) {
  const response = yield call(apiUrl.GetAllPlans);
  yield put(api.AllPlansData(response));
}
function* updateUserContactSaga(action) {
  const { navigate, showToast, setIsLoading, onBackToProfile, setShowOTPModal, ...payload } = action.payload;
  const response = yield call(apiUrl.changeUserContact, payload);
  if (response.status_code === 200) {
    if (response?.body?.codeRequired === false) {
      showToast('Contact Updated Successfully', 3000);
      setIsLoading(false);
      yield call(getUserProfileSaga, { showToast, setIsLoading });
      onBackToProfile();
    }
    else if (response?.body?.codeRequired === true) {
      setShowOTPModal(true);
      setIsLoading(false);
    }
  }
}
function* updateUserPincodeSaga(action) {
  const { navigate, showToast, setIsLoading, onContinue, ...payload } = action.payload;
  const response = yield call(apiUrl.changeUserPincode, payload);
  if (response.status_code === 204) {
    showToast('Pincode Updated Successfully', 3000);
    setIsLoading(false);
    onContinue();
  }
  else if (response.status_code !== 204) {
    showToast(response?.body?.message, 3000);
    setIsLoading(false);
  }
}
function* createPlanSaga(action) {
  const { showToast, setIsLoading, onContinue, planID, ...payload } = action.payload;
  const response = yield call(apiUrl.createPlan, payload, planID);
  if (response.status_code === 200) {
    showToast('Plan Created Successfully', 3000);
    setIsLoading(false);
    onContinue()
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* updatePlanSaga(action) {
  const { showToast, setIsLoading, onContinue, planID, ...payload } = action.payload;
  const response = yield call(apiUrl.updatePlan, payload, planID);
  if (response.status_code === 200) {
    showToast('Plan updated Successfully', 3000);
    setIsLoading(false);
    onContinue()
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* getUserProfileSaga(action) {
  const { showToast, setIsLoading } = action;
  const response = yield call(apiUrl.getUserProfile);
  if (response.status_code === 200) {
    localStorage.setItem('user', JSON.stringify(response?.body));
  }
  else if (response?.status_code === 401) {
    showToast('Error occured! Please Try again')
    setIsLoading(false)
  }
  else {
    setIsLoading(false);
  }
}
function* createPolicySaga(action) {
  const { navigate, showToast, setIsLoading, onContinue, ...payload } = action.payload;
  const response = yield call(apiUrl.createPolicy, payload);
  if (response.status_code === 200) {
    showToast('Policy Created Successfully', 3000);
    setIsLoading(false);
    onContinue()
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* updatePolicySaga(action) {
  const { showToast, setIsLoading, onContinue, policyID, ...payload } = action.payload;
  const response = yield call(apiUrl.updatePolicy, payload, policyID);
  if (response.status_code === 200) {
    showToast('Policy Updated Successfully', 3000);
    setIsLoading(false);
    onContinue()
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* deletePolicySaga(action) {
  const { showToast, policyID, ...payload } = action.payload;
  const response = yield call(apiUrl.deletePolicy, payload, policyID);
  if (response.status_code === 204) {
    showToast('Policy deleted Successfully', 3000);
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    showToast('Error occured! Please Try again', 3000)
  }
}
function* deletePlanSaga(action) {
  const { showToast, policyID, ...payload } = action.payload;
  const response = yield call(apiUrl.deletePlan, payload, policyID);
  if (response.status_code === 204) {
    showToast('Policy deleted Successfully', 3000);
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    showToast('Error occured! Please Try again', 3000)
  }
}
function* validateUserLoginSaga(action) {
  const { showToast, navigate, setIsLoggedIn } = action.payload;
  const response = yield call(apiUrl.userValidation);

  if (response.status_code === 200) {
    localStorage.setItem('user', JSON.stringify(response?.body));
  }
  else if (response?.status_code === 401) {
    localStorage.clear();
    setIsLoggedIn(false);
    showToast('You have been logged out. Please login again.', 3000)
    navigate('/')
  }
}
function* preparePaymentSaga(action) {
  const { showToast, navigate, setIsLoading, ...payload } = action.payload;
  const response = yield call(apiUrl.preparePayment, payload);
  if (response?.status_code === 200 && response?.body?.phone && response?.body?.requireAuth) {
    if(response?.body?.url){
        window.location.href = response?.body?.url;
    }else{
        const formData = {
            pp_MerchantID: process.env.REACT_APP_MERCHANT_ID,
            pp_Password: process.env.REACT_APP_MERCHANT_PW,
            pp_MSISDN: response?.body?.phone,
            pp_RequestID: response?.body?.requestId,
            pp_ReturnURL: response?.body?.returnUrl,
            pp_SecureHash: response?.body?.secureHash,
        };
        submitJazzCashForm(formData)
    }
  }
  if (response?.status_code === 200 && !response?.body?.requireAuth) {
    if (response?.body?.error) {
      navigate('/payment/failed/jazzcash', { state: response?.body?.message })
      setIsLoading(false);
    } else {
      navigate('/payment/success/jazzcash', { state: response?.body?.message })
      setIsLoading(false);
    }
  }
}
function* getUsersSaga(action) {
  const { setUsers, setTotalItems, ...payload } = action.payload;
  const response = yield call(apiUrl.users, payload);
  if (response.status_code === 200) {
    setUsers(response?.body?.response);
    setTotalItems(response?.body?.totalItemCount);
  }
}
function* unsubscripeUserPlanSaga(action) {
  const { showToast, onConfirm, setIsLoading, ...payload } = action.payload;
  const response = yield call(apiUrl.unsubscribePlan, payload);
  if (response.status_code === 204) {
    showToast('You have successfully unsubscribed');
    yield call(GetAppPlansSaga, action);
    yield delay(300);
    setIsLoading(false);
    onConfirm();
  } else {
    showToast('Error occured! Please Try again', 3000)
  }
}
function* accountLockSaga(action) {
  const { showToast, setUsers, setTotalItems, limit, page, setIsLoading, ...payload } = action.payload;
  const response = yield call(apiUrl.accountLock, payload);
  if (response.status_code === 200) {
    yield call(getUsersSaga, { setUsers, setTotalItems, payload: action.payload });
    yield delay(100);
    setIsLoading(false)
    if (response.body.locked) {
      showToast('User Account Locked Successfully', 3000)
    } else if (!response.body.locked) {
      showToast('User Account Unlocked Successfully', 3000)
    }

  }
}
function* accountBlockedSaga(action) {
  const { showToast, setUsers, setTotalItems, limit, page, setIsLoading, ...payload } = action.payload;
  const response = yield call(apiUrl.accountBlocked, payload);
  if (response.status_code === 200) {
    yield call(getUsersSaga, {
      setUsers, setTotalItems, payload: action.payload
    });
    yield delay(100);
    setIsLoading(false)
    if (response.body.locked) {
      showToast('User Account Locked Successfully', 3000)
    } else if (!response.body.locked) {
      showToast('User Account Unlocked Successfully', 3000)
    }

  }
}
function* setConfigurationsSaga(action) {
  const { showToast, setInputValues, setNewsPopup, setAdminConfigs, setLoadingConfigs, setIsLoading, ...payload } = action.payload;
  const response = yield call(apiUrl.setConfigurations, payload);
  if (response.status_code === 200) {
    yield call(getConfigurationsSaga, { payload: { setInputValues, setNewsPopup, setAdminConfigs, setLoadingConfigs } })
    showToast('Configurations Updated Successfully', 3000)
    setIsLoading(false);
  }
}
function* getConfigurationsSaga(action) {
  const { setInputValues, setNewsPopup, setAdminConfigs, setLoadingConfigs } = action.payload;
  const response = yield call(apiUrl.getConfigurations);
  if (response.status_code === 200) {
    setAdminConfigs(response?.body);
    setInputValues(response?.body);
    setNewsPopup({
      generalAuthSignupPopup: response?.body?.generalAuthSignupPopup,
      generalAuthSignupContent: response?.body?.generalAuthSignupContent,
      generalUserSignupPopup: response?.body?.generalUserSignupPopup,
      generalUserSignupContent: response?.body?.generalUserSignupContent,
      generalSubscribedPopup: response?.body?.generalSubscribedPopup,
      generalSubscribedContent: response?.body?.generalSubscribedContent
    });
    setLoadingConfigs(false);
    yield delay(500);
  }
}
function* setHospitalsSaga(action) {
  const { showToast, setIsLoading, setHospitalsForTable, ...payload } = action.payload;
  const response = yield call(apiUrl.setHospitals, payload);
  if (response.status_code === 200) {
    setIsLoading(false);
    showToast('Hospital Added Successfully', 3000);
    yield call(getHospitalsSaga, { payload: { setHospitalsForTable } });
  }
}
function* updateHospitalsSaga(action) {
  const { showToast, setIsLoading, setHospitalsForTable, hospitalID, ...payload } = action.payload;
  const response = yield call(apiUrl.updateHospitals, payload, hospitalID);
  if (response.status_code === 200) {
    setIsLoading(false);
    showToast('Hospital updated Successfully', 3000);
    yield call(getHospitalsSaga, { payload: { setHospitalsForTable } });
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* deleteHospitalsSaga(action) {
  const { showToast, setIsLoading, setHospitalsForTable, setOpenConfirmationDialog, hospitalID, ...payload } = action.payload;
  const response = yield call(apiUrl.deleteHospitals, payload, hospitalID);
  if (response.status_code === 204) {
    setIsLoading(false);
    showToast('Hospital Deleted Successfully', 3000);
    setOpenConfirmationDialog(false);
    yield call(getHospitalsSaga, { payload: { setHospitalsForTable } });
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* getHospitalsSaga(action) {
  const { setHospitalsForTable } = action.payload;
  const response = yield call(apiUrl.getHospitals);
  if (response.status_code === 200) {
    setHospitalsForTable(response?.body);
  }
}
function* getAnnouncementsSaga(action) {
  const { setAnnouncements } = action.payload;
  const response = yield call(apiUrl.getAnnouncements);
  if (response.status_code === 200) {
    setAnnouncements(response?.body);
  }
}
function* getAnnouncementsForUsersSaga(action) {
  const { setAnnouncements } = action.payload;
  const response = yield call(apiUrl.getAnnouncementsForUsers);
  if (response.status_code === 200) {
    setAnnouncements(response?.body);
  }
}
function* setAnnouncementsSaga(action) {
  const { showToast, setIsLoading, setAnnouncements, ...payload } = action.payload;
  const response = yield call(apiUrl.setAnnouncements, payload);
  if (response.status_code === 200) {
    setIsLoading(false);
    showToast('Announcement Added Successfully', 3000);
    yield call(getAnnouncementsSaga, { payload: { setAnnouncements } });
  }
}
function* updateAnnouncementsSaga(action) {
  const { showToast, setIsLoading, setAnnouncements, announcementID, ...payload } = action.payload;
  const response = yield call(apiUrl.updateAnnouncements, payload, announcementID);
  if (response.status_code === 200) {
    setIsLoading(false);
    showToast('Announcement updated Successfully', 3000);
    yield call(getAnnouncementsSaga, { payload: { setAnnouncements } });
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* deleteAnnouncementsSaga(action) {
  const { showToast, setIsLoading, setAnnouncements, setOpenConfirmationDialog, announcementID, ...payload } = action.payload;
  const response = yield call(apiUrl.deleteAnnouncements, payload, announcementID);
  if (response.status_code === 204) {
    setIsLoading(false);
    showToast('Announcement Deleted Successfully', 3000);
    setOpenConfirmationDialog(false);
    yield call(getAnnouncementsSaga, { payload: { setAnnouncements } });
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* setReferralsSaga(action) {
  const { showToast, setIsLoading, setReferralsData, ...payload } = action.payload;
  const response = yield call(apiUrl.setReferrals, payload);
  if (response.status_code === 200) {
    setIsLoading(false);
    showToast('Referral Added Successfully', 3000);
    yield call(getReferralsSaga, { payload: { setReferralsData, showToast, limit: 10, page: 1 } });
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* getReferralsSaga(action) {
  const { showToast, setReferralsData, setTotalItems, ...payload } = action.payload;
  const response = yield call(apiUrl.getReferrals, payload);
  if (response.status_code === 200) {
    setReferralsData(response?.body);
  }
  else {
    setReferralsData([]);
    showToast('Error loading data! Please Try again later', 3000)
  }
}
function* updateReferralsSaga(action) {
  const { showToast, setIsLoading, setReferralsData, referralID, ...payload } = action.payload;
  const response = yield call(apiUrl.updateReferrals, payload, referralID);

  if (response.status_code === 200) {
    setIsLoading(false);
    showToast('Referral Updated Successfully', 3000);
    yield call(getReferralsSaga, { payload: { setReferralsData, showToast, limit: 10, page: 1 } });
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* deleteReferralsSaga(action) {
  const { showToast, setIsLoading, setReferralsData, setOpenConfirmationDialog, referralID, ...payload } = action.payload;
  const response = yield call(apiUrl.deleteReferrals, payload, referralID);
  if (response.status_code === 204) {
    setIsLoading(false);
    showToast('Referral Deleted Successfully', 3000);
    setOpenConfirmationDialog(false);
    yield call(getReferralsSaga, { payload: { setReferralsData, showToast, limit: 10, page: 1 } });
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* getPlanPricingSaga(action) {
  const { showToast, setRecurringPayments, policyID, ...payload } = action.payload;
  const response = yield call(apiUrl.getPlanPricings, payload, policyID);
  if (response.status_code === 200) {
    setRecurringPayments(response?.body);
  }
  else {
    showToast('Error loading data! Please Try again later', 3000)
  }
}
function* setPlanPricingSaga(action) {
  const { showToast, policyID, onClose, ...payload } = action.payload;
  const response = yield call(apiUrl.setPlanPricings, payload, policyID);
  if (response.status_code === 204) {
    showToast('Plan Pricing Updated Successfully', 3000)
    onClose();
  }
  else {
    showToast('Error saving data! Please Try again later', 3000)
  }
}
function* resetUserPinSaga(action) {
  const { setUsers, setTotalItems, setIsLoading, page, showToast, setOpenResetPinCodeDialog, limit, userPin, ...payload } = action.payload;
  const response = yield call(apiUrl.resetUserPin, payload, userPin);
  if (response.status_code === 200) {
    setIsLoading(false);
    showToast('User Pin Reset Successfully', 3000);
  }
}
function* updateUserForAdminSaga(action) {
  const { setIsLoading, onContinue, showToast, userID, ...payload } = action.payload;
  const response = yield call(apiUrl.updateUserFromAdmin, payload, userID);
  if (response.status_code === 200) {
    setIsLoading(false);
    onContinue()
    showToast('User has been updated', 3000);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* deleteUserForAdminSaga(action) {
  const { setOpenDeleteDialog, fetchUsers, showToast, userID, ...payload } = action.payload;
  const response = yield call(apiUrl.deleteUserFromAdmin, payload, userID);
  if (response.status_code === 204) {
    setOpenDeleteDialog(false);
    fetchUsers()
    showToast('User has been updated', 3000);
  }
  else {
    setOpenDeleteDialog(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* getTransactionsSaga(action) {
  const { setTransactions, setTotalItems, ...payload } = action.payload;
  const response = yield call(apiUrl.getTransactions, payload);
  if (response.status_code === 200) {
    setTransactions(response?.body?.response);
    setTotalItems(response?.body?.totalItemCount);
  }
}
function* getTransactionDetailsSaga(action) {
  const { setTransactionDetail, transactionID, setViewModal, setIsLoadingPopup, ...payload } = action.payload;
  const response = yield call(apiUrl.getTransactionDetails, payload, transactionID);
  if (response.status_code === 200) {
    setViewModal(true);
    setTransactionDetail(response?.body);
    setIsLoadingPopup(false);
  }
}
function* refundTransactionSaga(action) {
  const { setIsLoading, transactionID,onClose, showToast, ...payload } = action.payload;
  const response = yield call(apiUrl.refundTransaction, payload, transactionID);
  if (response.status_code === 200) {
    setIsLoading(false);
    onClose();
    showToast(response?.body?.pp_ResponseMessage, 3000);
  }
  if(response.status_code === 500){
    setIsLoading(false);
    onClose();
    showToast(response?.body?.message, 3000);
  }
}
function* revokeTokenSaga(action) {
  const { showToast, setIsLoggedIn,setIsOpen,navigate } = action.payload;
  const response = yield call(apiUrl.RevokeTokenApi);
  if (response.status_code === 204) {
    setIsLoggedIn(false);
    showToast('Logged out successfully', 3000);
    setIsOpen(false);
    navigate('/');
    localStorage.clear();
  }
}

function* RootSaga() {
  yield takeEvery(api.setAdminConfigurations.type, setConfigurationsSaga);
  yield takeEvery(api.updateUserForAdmin.type, updateUserForAdminSaga);
  yield takeEvery(api.revokeUserToken.type, revokeTokenSaga);
  yield takeEvery(api.getTransactionsForAdmin.type, getTransactionsSaga);
  yield takeEvery(api.refundTransactionForAdmin.type, refundTransactionSaga);
  yield takeEvery(api.getTransactionDetailsForAdmin.type, getTransactionDetailsSaga);
  yield takeEvery(api.deleteUserForAdmin.type, deleteUserForAdminSaga);
  yield takeEvery(api.resetPincodeForUser.type, resetUserPinSaga);
  yield takeEvery(api.getPlanPricingData.type, getPlanPricingSaga);
  yield takeEvery(api.setPlanPricingData.type, setPlanPricingSaga);
  yield takeEvery(api.getAdminConfigurations.type, getConfigurationsSaga);
  yield takeEvery(api.setHospitalsData.type, setHospitalsSaga);
  yield takeEvery(api.deleteHospitalsData.type, deleteHospitalsSaga);
  yield takeEvery(api.updateHospitalsData.type, updateHospitalsSaga);
  yield takeEvery(api.getHospitalsData.type, getHospitalsSaga);
  yield takeEvery(api.getAnnouncementsData.type, getAnnouncementsSaga);
  yield takeEvery(api.getAnnouncementsForUsersData.type, getAnnouncementsForUsersSaga);
  yield takeEvery(api.setAnnouncementsData.type, setAnnouncementsSaga);
  yield takeEvery(api.updateAnnouncementsData.type, updateAnnouncementsSaga);
  yield takeEvery(api.deleteAnnouncementsData.type, deleteAnnouncementsSaga);
  yield takeEvery(api.setReferralCode.type, setReferralsSaga);
  yield takeEvery(api.getReferralsData.type, getReferralsSaga);
  yield takeEvery(api.updateReferralsData.type, updateReferralsSaga);
  yield takeEvery(api.deleteReferralsData.type, deleteReferralsSaga);
  yield takeEvery(api.callLogin.type, LoginSaga);
  yield takeEvery(api.lockUserAccount.type, accountLockSaga);
  yield takeEvery(api.blockUserAccount.type, accountBlockedSaga);
  yield takeEvery(api.getUsers.type, getUsersSaga);
  yield takeEvery(api.unsubscribeUserPlan.type, unsubscripeUserPlanSaga);
  // yield takeEvery(refreshTokenRequested.type, refreshTokenSaga);
  yield takeEvery(api.callValidateUser.type, validateUserSaga);
  yield takeEvery(api.callSignUp.type, SignUpSaga);
  yield takeEvery(api.GetPlans.type, GetAppPlansSaga);
  yield takeEvery(api.updateUser.type, updateUserProfileSaga);
  yield takeEvery(api.updateContact.type, updateUserContactSaga);
  yield takeEvery(api.changePincode.type, updateUserPincodeSaga);
  yield takeEvery(api.createPlanForPolicy.type, createPlanSaga);
  yield takeEvery(api.getUser.type, getUserProfileSaga);
  yield takeEvery(api.createPolicyCover.type, createPolicySaga)
  yield takeEvery(api.updateUserPolicy.type, updatePolicySaga)
  yield takeEvery(api.deleteUserPolicy.type, deletePolicySaga)
  yield takeEvery(api.updateUserPlan.type, updatePlanSaga)
  yield takeEvery(api.deleteUserPlan.type, deletePlanSaga)
  yield takeEvery(api.prepareUserPayment.type, preparePaymentSaga)
  yield takeEvery(api.validateUser.type, validateUserLoginSaga)
}

export default RootSaga;
