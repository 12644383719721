/* eslint-disable no-unused-vars */
import { useEffect, useContext, useState } from "react";
import { getAdminConfigurations } from "../features/api.slice";
import { useAppDispatch } from "../store";
import AdminContext from "../contexts/AdminConfigs";

function GetAdminConfigs() {
    // Handle the admin config loading
    const dispatch = useAppDispatch();
    const [inputValues, setInputValues] = useState();
    const [newPopup, setNewsPopup] = useState();
    const { setAdminConfigs, setLoadingConfigs } = useContext(AdminContext);
    const payload = {
        setAdminConfigs,
        setInputValues,
        setNewsPopup,
        setLoadingConfigs,
    }
    useEffect(() => {
        dispatch(getAdminConfigurations(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

}

export default GetAdminConfigs;