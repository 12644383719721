import React, { useState } from 'react';
import './otp.scss';
import TitleAndLogo from '../../TitleAndLogo/titleAndLogo';
import SingleDigitInputs from '../SingleDigitInput/singleDigitInput';
import { useLocation } from "react-router-dom";
import { useAppDispatch } from '../../../store';
import { callSignUp } from "../../../features/api.slice";

function OTPModal({ onContinue, wrapperClass }) {
  const location = useLocation()
  const [otp, setOtp] = useState('')
  const phoneNumber = location?.state?.phoneNumber
  const referralCode = location?.state?.referralCode
  const dispatch = useAppDispatch()
  function handleOtpChange(e) {
    setOtp(e)
  }
  function verifyOtp() {
    const payload = {
      grant_type: "password",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      phone: phoneNumber,
      password: "",
      code: otp,
      referral_code: referralCode
    }
    dispatch(callSignUp(payload))
    onContinue()
  }

  return (
    <div style={wrapperClass} className={wrapperClass ? wrapperClass : "otp-modal-wrapper"}>
      <div className="otp-modal">
        <div className="title-logo-container">
          <TitleAndLogo logoColor="pink" />
        </div>
        <h2>Enter OTP</h2>
        <p>We have sent you a code via SMS on {phoneNumber}.<span className="change-number">Change number</span></p>
        <SingleDigitInputs onChange={(e) => { handleOtpChange(e) }} />
        <button className="continue-button" onClick={verifyOtp}>Continue</button>
        <p className="resend-text"><strong>Didn’t get?</strong> Request another after <span>60s</span></p>
      </div>
    </div>
  );
};

export default OTPModal;
