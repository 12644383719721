// SpinnerPage.jsx

import React from 'react';
import './Spinner.scss'; // Import CSS for styling

const SpinnerPage = ({ loading }) => {
  return (
    <>
      {/* Spinner Overlay */}
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
};

export default SpinnerPage;
