import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, useMediaQuery, TableHead, Box, TableRow, Paper, TableFooter, TablePagination, CircularProgress, Typography, IconButton, Backdrop, Tooltip, Chip } from '@mui/material';
import { useAppDispatch } from '../../../store';
import { getTransactionDetailsForAdmin, getTransactionsForAdmin } from '../../../features/api.slice';
import Visibility from '@mui/icons-material/Visibility';
import TransactionModal from './Modal/TransactionModal';
import SpinnerPage from './../../../components/Spinner/Spinner';
import formatDate from './../../../utils/global';
import WalletIcon from '@mui/icons-material/Wallet';
import RefundModal from './Modal/RefundModal';
function Transactions() {
    const [transactions, setTransactions] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [totalItems, setTotalItems] = useState(0); // Total items count
    const [rowsPerPage, setRowsPerPage] = useState(10); // Default items per page
    const [page, setPage] = useState(0); // Current page
    const dispatch = useAppDispatch();
    const [isLoadingPopup, setIsLoadingPopup] = useState(false);
    const [isLoadingTableData, setIsLoadingTableData] = useState(true);
    const [viewRefundModal, setViewRefundModal] = useState(false);
    useEffect(() => {
        fetchTransactions(); // Function to handle fetching users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]); // Re-fetch users when these dependencies change

    // Fetch users function with search parameter
    const fetchTransactions = () => {
        const payload = {
            limit: rowsPerPage,
            page: page + 1,
            setTotalItems,
            setTransactions: (data) => {
                setTransactions(data);
                setIsLoadingTableData(false);
            }
        };
        dispatch(getTransactionsForAdmin(payload));
    };
    const handleChangePage = (event, newPage) => {
        setIsLoadingTableData(true);
        setPage(newPage);
    };
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when changing rows per page
    };
    const [transactionDetails, setTransactionDetail] = useState({});
    const [refundTransaction, setRefundTransaction] = useState({});
    function handleViewTransactionDetails(transaction) {
        // Handle view transaction details
        setIsLoadingPopup(true);
        const payload = {
            setTransactionDetail,
            setViewModal,
            transactionID: transaction?.id,
            setIsLoadingPopup,
        }
        dispatch(getTransactionDetailsForAdmin(payload));
    }
    function handleRefundAmount(transaction) {
        setRefundTransaction(transaction);
        setViewRefundModal(true);
        // Handle refund amount
    }
    function closeRefundModal() {
        setViewRefundModal(false);
        setRefundTransaction({});
        fetchTransactions();
    }
    const isMobile = useMediaQuery('(max-width:600px)');
    function closeModal() {
        setViewModal(false);
        setTransactionDetail({});
        fetchTransactions();
    }
    const renderStatusPill = (state) => {

        return state === 'complete' ? (
            <Chip label="Complete" color="success" size="small" className="status-pill" />
        ) : state === 'refunded' ? (
            <Chip label="Refunded" color="warning" size="small" className="status-pill" />
        ) : (
            <Chip label={state} color="error" size="small" className="status-pill" />
        );

    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                backgroundColor: '#f5f5f5',
                minHeight: '100vh',
            }}
        >
            <div className="policy-header align-center">
                <h2 className="header-title">Transactions</h2>
            </div>
            <TableContainer
                component={Paper}
                className="table-container"
                sx={{
                    minHeight: 'calc(100vh - 250px)',  // Fixed height for the table
                    maxHeight: 'calc(100vh - 250px)',  // Maximum height for the table
                    overflowX: 'auto',   // Enable horizontal scrolling
                    overflowY: 'auto',   // Enable vertical scrolling
                }}
            >
                <Table stickyHeader>
                    <TableHead className="user-table-header">
                        <TableRow className="table-cell">
                            <TableCell>User</TableCell>
                            <TableCell>User Contact</TableCell>
                            <TableCell>Gateway</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created at</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoadingTableData && (
                            <TableRow>
                                <TableCell rowSpan={rowsPerPage} colSpan={9} style={{ textAlign: 'center' }}>
                                    <CircularProgress sx={{ color: '#E44E80' }} />
                                    <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                                        Transactions are loading...
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {transactions?.length === 0 && !isLoadingTableData && (
                            <TableRow>
                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                    <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                                        No users found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {!isLoadingTableData &&
                            transactions.map((row) => (
                                <TableRow key={row.id} className="table-row table-cell">
                                    <TableCell>{row?.user?.first_name}</TableCell>
                                    <TableCell>{row?.user?.phone}</TableCell>
                                    <TableCell>{row.gateway}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{renderStatusPill(row.state)}</TableCell>
                                    <TableCell>{formatDate(row.created_at)}</TableCell>
                                    <TableCell>{row.currency}</TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Refund">
                                            <IconButton disabled={row.state !=='complete'} onClick={() => handleRefundAmount(row)}>
                                                <WalletIcon fontSize='small' style={{ cursor: 'pointer' }} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="View Details">
                                            <IconButton onClick={() => handleViewTransactionDetails(row)}>
                                                <Visibility fontSize='small' style={{ cursor: 'pointer' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableFooter
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white', // Keeps pagination background visible
                    zIndex: 1,                 // Ensures it stays on top when scrolling
                }}
            >
                <TableRow>
                    <TablePagination
                        colSpan={5}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </TableRow>
            </TableFooter>
            {viewModal && (
                <Backdrop
                    open={viewModal}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                >
                    <TransactionModal
                        dataToShow={transactionDetails}
                        wrapperClass={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            ...(isMobile
                                ? { width: '80%', height: 'auto', padding: '16px' }
                                : { height: '100%', width: '100%' }),
                        }}
                        onContinue={closeModal}
                    />
                </Backdrop>
            )}
            {viewRefundModal && (
                <Backdrop
                    open={viewRefundModal}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                >
                    <RefundModal
                        refundData={refundTransaction}
                        wrapperClass={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            ...(isMobile
                                ? { width: '80%', height: 'auto', padding: '16px' }
                                : { height: '100%', width: '100%' }),
                        }}
                        onContinue={closeRefundModal}
                    />
                </Backdrop>
            )}
            <SpinnerPage loading={isLoadingPopup} />

        </Box>

    );
}

export default Transactions;
