import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
const StyledDialogTitle = styled(DialogTitle)({
    fontSize: '20px',
    fontWeight: 'bold',
    paddingTop: '20px',
});

const StyledDialogContent = styled(DialogContent)({
    padding: '20px',
});

const StyledDialogContentText = styled(DialogContentText)({
    fontSize: '16px',
    color: '#555',
    marginBottom: '20px',
});

const StyledLink = styled(Link)({
    color: '#E44E80',
    textDecoration: 'none',
    cursor: 'pointer',
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: 'flex-end',
    paddingBottom: '20px',
    paddingRight: '20px',
});

const CancelButton = styled(Button)({
    borderColor: '#E44E80',
    color: '#E44E80',
    marginRight: '10px',
    '&:hover': {
        borderColor: '#d13b6f',
    },
});

const ContinueButton = styled(Button)({
    backgroundColor: '#E44E80',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#d13b6f',
    },
});

const HealthDeclarationModal = ({ open, handleClose, handleContinue }) => {
    const userData = JSON.parse(localStorage.getItem('user')) || {};
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="health-declaration-title"
            aria-describedby="health-declaration-description"
            maxWidth="sm"
            fullWidth
        >
            <StyledDialogTitle id="health-declaration-title">
                Health Declaration and Acknowledgment
            </StyledDialogTitle>
            <StyledDialogContent>
                <StyledDialogContentText id="health-declaration-description">
                By continuing, I ({userData?.first_name}) confirm accurately to the best of my knowledge and belief that 
                I am currently (on {dayjs(new Date()).format('DD-MMM-YYYY')}) not being investigated, diagnosed, treated or have undergone 
                treatment in the past with any form of malignant tumor, cancer, leukemia, lymphoma or any 
                other form of malignant or potentially malignant condition. I understand that this declaration
                 is a material representation of my health status at the time of subscription to ITTEHAAD 
                and I understand and agree to the{ ' '}
                    <StyledLink href="https://ittehaad.com/faqs/#terms-and-conditions" target='blank' underline="always">
                        terms & conditions
                    </StyledLink>{' '}
                    of ITTEHAAD Cancer Cover.
                </StyledDialogContentText>
            </StyledDialogContent>
            <StyledDialogActions>
                <CancelButton variant="outlined" onClick={handleClose}>
                    Cancel
                </CancelButton>
                <ContinueButton variant="contained" onClick={handleContinue}>
                    Continue
                </ContinueButton>
            </StyledDialogActions>
        </Dialog>
    );
};

export default HealthDeclarationModal;
