import React, { createContext, useState } from 'react';

const AdminContext = createContext();

export const AdminConfigProvider = ({ children }) => {
    const [adminConfigs, setAdminConfigs] = useState({});
    const [loadingConfigs, setLoadingConfigs] = useState(true);  // Track loading state for configs
    return (
        <AdminContext.Provider value={{ adminConfigs, setAdminConfigs,loadingConfigs, setLoadingConfigs    }}>
            {children}
        </AdminContext.Provider>
    );
};

export default AdminContext;
