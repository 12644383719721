import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Alert, Button } from '@mui/material'; // Import Material-UI components

const FailedMessage = () => {
  const location = useLocation();
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };
  const failedMsg = location?.state
  // Get message from URL or show a default failure message
  const queryParams = getQueryParams(location.search);
  const message = queryParams.get('message') || failedMsg || 'Subscription Failed!';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '10%' }}>
      <Alert severity="error" style={{ width: '100%', maxWidth: '600px', marginBottom: '20px' }}>
        {message}
      </Alert>
      <Button 
        component={Link} 
        to="/dashboard" 
        variant="contained" 
        color="secondary" 
        style={{ textDecoration: 'none' }}
      >
        Go to Dashboard
      </Button>
    </div>
  );
};

export default FailedMessage;
