import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    useMediaQuery,
    Typography,
    CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './HospitalTable.scss'; // Assuming custom styles

const HospitalsTable = ({ hospitals, onEdit, onDelete }) => {
    // Detect screen size for responsiveness
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <TableContainer component={Paper} className='height--40'>
            {!isMobile ? (
                <Table>
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell>Hospital Name</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Website</TableCell>
                            <TableCell>Onboarding Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            hospitals?.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                    <CircularProgress sx={{ color: '#E44E80' }} />
                                        <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                                            Loading Hospitals...
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        {
                            hospitals?.response?.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <Typography variant="body1">No hospitals available.</Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        {hospitals?.response?.map((hospital, index) => (
                            <TableRow key={index}>
                                <TableCell>{hospital.title}</TableCell>
                                <TableCell>{hospital.city}</TableCell>
                                <TableCell>{hospital.phone}</TableCell>
                                <TableCell>{hospital.email}</TableCell>
                                <TableCell>{hospital.website}</TableCell>
                                <TableCell>{hospital.onboarding_date}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => onEdit(hospital)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => onDelete(hospital.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                hospitals?.response?.map((hospital, index) => (
                    <Paper className="mobile-card" key={index}>
                        <Typography variant="h6">
                            {hospital.title}
                        </Typography>
                        <Typography>
                            <strong>City:</strong> {hospital.city}
                        </Typography>
                        <Typography>
                            <strong>Phone:</strong> {hospital.phone}
                        </Typography>
                        <Typography>
                            <strong>Email:</strong> {hospital.email}
                        </Typography>
                        <Typography>
                            <strong>Website:</strong> {hospital.website}
                        </Typography>
                        <Typography>
                            <strong>Onboarding Date:</strong> {hospital.onboarding_date}
                        </Typography>
                        <div className="mobile-actions">
                            <IconButton
                                color="primary"
                                onClick={() => onEdit(hospital)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                color="secondary"
                                onClick={() => onDelete(hospital.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </Paper>
                ))

            )}
        </TableContainer>
    );
};

export default HospitalsTable;
