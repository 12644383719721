import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import SignUp from './pages/Signup/Signup';
import './App.scss';
import PageTemplate from './pages/PageTemplate/PageTemplate';
import ModalContainer from './pages/Modal/Modal';
import Payment from './pages/Payment/payment';
import Dashboard from './components/Dashboard/dashboard';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import MobileContributionComponent from './components/PolicyCover/mobileContributionComponent';
import EnterDetails from './pages/ProfilePage/enterDetails';
import UserPlans from './components/UserPlans/userPlans';
import Policy from './pages/Admin/Policy/Policy';
import Users from './pages/Admin/Users/Users';
import PlanContext from './contexts/PlanContext';
import NotFound from './components/404-NotFound/NotFound';
import SuccessMessage from './pages/Payment/Message/SuccessMessage';
import FailedMessage from './pages/Payment/Message/FailedMessage';
import { useAppDispatch } from './store';
import { validateUser } from './features/api.slice';
import { useToast } from './components/ToastNotification/ToastNotification';
import AdminConfigurationPage from './pages/Admin/Configurations/Configuration';
import AdminContext from './contexts/AdminConfigs';
import GetAdminConfigs from './hooks/getAdminConfigs';
import SpinnerPage from './components/Spinner/Spinner';
import AnnouncementPage from './pages/Announcements/Announcements';
import Hospitals from './pages/Hospitals/Hospitals';
import RecurringPayments from './pages/Admin/RecurringPayments/RecurringPayments';
import Reports from './pages/Admin/Reports/Reports';
import Transactions from './pages/Admin/Transactions/Transactions';
function App() {
  const { isLoggedIn, setIsLoggedIn } = useContext(PlanContext);
  const { loadingConfigs } = useContext(AdminContext);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  GetAdminConfigs();

  if (loadingConfigs) {
    return <SpinnerPage loading={loadingConfigs} />;  // Show a loading spinner or message while configs load
  }

  return (
    <Router>
      <ScrollToTop />
      <TokenValidationWrapper />
      <Routes>
        <Route path="/" element={isLoggedIn ? <PageTemplate><Dashboard /></PageTemplate> : <SignUp />} />
        <Route path="/contribution" element={<MobileContributionComponent />} />
        <Route path="/announcements" element={<PageTemplate><AnnouncementPage /></PageTemplate>} />
        <Route path="/hospital" element={<PageTemplate><Hospitals /></PageTemplate>} />
        <Route path="/modal" element={<ModalContainer />} />
        <Route path="/dashboard" element={<PageTemplate><Dashboard /></PageTemplate>} />
        <Route path="/user-plans" element={<PageTemplate><UserPlans /></PageTemplate>} />
        <Route path="/transactions" element={<PageTemplate><Transactions /></PageTemplate>} />
        <Route path="/profile" element={<PageTemplate><ProfilePage /></PageTemplate>} />
        <Route path="/coverage-type" element={<PageTemplate><Policy /></PageTemplate>} />
        <Route path="/reports" element={<PageTemplate><Reports /></PageTemplate>} />
        <Route path="/admin-configuration" element={<PageTemplate><AdminConfigurationPage /></PageTemplate>} />
        <Route path="/recurring-payment" element={<PageTemplate><RecurringPayments /></PageTemplate>} />
        <Route path="/users" element={<PageTemplate><Users /></PageTemplate>} />
        <Route path="/details" element={<PageTemplate><EnterDetails /></PageTemplate>} />
        <Route path="/payment" element={<PageTemplate><Payment /></PageTemplate>} />
        <Route path="/payment/success/jazzcash" element={<PageTemplate><SuccessMessage /></PageTemplate>} />
        <Route path="/payment/failed/jazzcash" element={<PageTemplate><FailedMessage /></PageTemplate>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
function ScrollToTop() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // optional, adds smooth scrolling animation
    });
  }, [navigate]);
}
function TokenValidationWrapper() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setIsLoggedIn } = useContext(PlanContext);

  const showToast = useToast();
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      async function validateToken() {
        const payload = {
          showToast,
          navigate,
          setIsLoggedIn,
        }
        dispatch(validateUser(payload));
      };
      validateToken();
    }
    else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return null;
}

export default App;
