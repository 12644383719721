import React, { useState } from 'react';
import { Modal, Box, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@mui/material';
import './unsubscribeModal.scss';
import { useAppDispatch } from '../../store';
import { unsubscribeUserPlan } from '../../features/api.slice';
import { useToast } from '../ToastNotification/ToastNotification';

const UnsubscribeModal = ({ open, onClose, onConfirm }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };
    const showToast = useToast();
    function handleContinueUnsubscription() {
        setIsLoading(true);
        // Add your code here
        const payload ={
            reason: selectedReason,
            showToast,
            setIsLoading,
            onConfirm,
        }
        dispatch(unsubscribeUserPlan(payload));
    }
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="unsubscribe-modal">
            <Box className="modal-overlay">
                <Box className="unsubscribe-modal-box">
                    <Typography variant="h5" className="modal-title">Unsubscribe</Typography>
                    <Typography variant="body1" className="modal-subtitle">
                        Are you sure you want to unsubscribe? Please let us know the reason to unsubscribe, so we can improve our program.
                    </Typography>
                    <RadioGroup value={selectedReason} onChange={handleReasonChange}>
                        <FormControlLabel value="Too expensive" control={<Radio />} label="Too expensive" />
                        <FormControlLabel value="No longer need the membership" control={<Radio />} label="No longer need the membership" />
                        <FormControlLabel value="I’m changing my bank" control={<Radio />} label="I’m changing my bank" />
                        <FormControlLabel value="Not satisfied with program dynamics" control={<Radio />} label="Not satisfied with program dynamics" />
                        <FormControlLabel value="Had subscribed by mistake" control={<Radio />} label="Had subscribed by mistake" />
                    </RadioGroup>

                    <Button variant="outlined" onClick={onClose} className="continue-button">
                        Continue Subscription
                    </Button>
                    <Button variant="contained" color="error" disabled={isLoading} onClick={handleContinueUnsubscription} className={isLoading ? 'disabled-button confirm-button':"confirm-button"}>
                       {isLoading ? 'Cancelling...': 'Confirm Cancel Subscription'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UnsubscribeModal;
