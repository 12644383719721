import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import './Configuration.scss';
import HospitalForm from './HospitalForm/HospitalForm';
import AdminConfigs from './AdminConfigs/AdminConfigs';
import AnnouncementsForm from './Announcements/Announcements';
import ReferralForm from './ReferralForm/ReferralForm';

const AdminConfigurationPage = () => {
    const [currentTab, setCurrentTab] = useState(0); // State to manage the currently selected tab

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue); // Update the current tab when a new one is selected
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: '20px',
                backgroundColor: '#f5f5f5',
                minHeight: '100vh',
            }}
        >
            <Tabs value={currentTab} onChange={handleChange} sx={{
                '& .MuiTabs-indicator': {
                    backgroundColor: '#E44E80', // Hide default indicator
                },
                '& .MuiTab-root.Mui-selected': {
                    color: 'black', // Change text color if needed
                },
                '& .MuiTab-root': {
                    color: 'black', // Change text color if needed
                },
            }}>
                <Tab label="Admin Configs" />
                <Tab label="Hospital Form" />
                <Tab label="Announcements" />
                <Tab label="Referral Code" />
            </Tabs>
            {currentTab === 0 && <AdminConfigs />}
            {currentTab === 1 && <HospitalForm />}
            {currentTab === 2 && <AnnouncementsForm />}
            {currentTab === 3 && <ReferralForm />}
        </Box>
    );
};

export default AdminConfigurationPage;
