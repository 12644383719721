import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box, Button, CircularProgress } from '@mui/material';
import './Hospitals.scss'; // Include custom styling for the sleek design
import { useAppDispatch } from '../../store';
import { getHospitalsData } from '../../features/api.slice';
import formatDate from '../../utils/global';

function Hospitals() {
    const dispatch = useAppDispatch();
    const [hospitals, setHospitalsForTable] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const payload = {
                setHospitalsForTable: (data) => {
                    setHospitalsForTable(data);
                    setIsLoading(false);
                }
            };
            dispatch(getHospitalsData(payload));
        };
        
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: '20px',
                backgroundColor: '#f5f5f5',
                minHeight: '100vh',
            }}
        >
            <div className="policy-header">
                <h2 className="header-title">Partner Hospitals</h2>
            </div>
            {isLoading ? (
                // Show loading spinner and message while data is being fetched
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                    <CircularProgress sx={{ color: '#E44E80' }}/>
                    <Typography variant="h6" color="textSecondary" sx={{ marginTop: '10px' }}>
                        Hospitals are loading...
                    </Typography>
                </Box>
            ) : hospitals?.response?.length > 0 ? (
                // Display the hospitals once data has loaded and there's data
                <Grid container spacing={2}>
                    {hospitals.response.map((hospital) => (
                        <Grid item xs={12} sm={6} md={4} key={hospital.id}>
                            <Card
                                sx={{
                                    width: '100%',
                                    height: { xs: 400, sm: 380 },
                                    background: 'white',
                                    borderRadius: '20px',
                                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
                                    transition: 'all 0.3s ease-in-out',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    '&:hover': {
                                        transform: 'translateY(-10px)',
                                        boxShadow: '0 16px 30px rgba(0, 0, 0, 0.15)',
                                    },
                                }}
                                className="hospital-card"
                            >
                                <CardContent
                                    sx={{
                                        textAlign: 'center',
                                        padding: '15px',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontWeight: 600,
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {hospital.title}
                                    </Typography>
                                    {/* Hospital Info */}
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        <strong>City:</strong> {hospital.city}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        <strong>Phone:</strong> <a href={`tel:${hospital.phone}`}>{hospital.phone}</a>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        <strong>Email:</strong> <a href={`mailto:${hospital.email}`}>{hospital.email}</a>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        <strong>Website:</strong> <a href={hospital.website} target="_blank" rel="noopener noreferrer">{hospital.website}</a>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        <strong>Onboarded:</strong>{' '}
                                        {formatDate(hospital.onboarding_date)}
                                    </Typography>
                                </CardContent>
                                <Button
                                    href={hospital.website}
                                    variant="outlined"
                                    sx={{
                                        margin: '0 15px 20px',
                                        color: '#e44e80',
                                        borderColor: '#e44e80',
                                        '&:hover': {
                                            backgroundColor: '#e44e80',
                                            color: '#fff',
                                        },
                                    }}
                                >
                                    Visit Website
                                </Button>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                // Show message if no hospitals are available
                <Typography variant="h6" align="center" color="textSecondary" sx={{ marginTop: '20px' }}>
                    No hospitals to show
                </Typography>
            )}
        </Box>
    );
}

export default Hospitals;
