import Header from "../../components/Header/header";
import { useNavigate } from 'react-router-dom';
import LeftNavMenu from "../../components/LeftNav/leftnav";
import { useContext, useRef, useState } from 'react';
import { useMediaQuery, useTheme, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ProfileDropdown from "../../components/UserDropdown/profileDropdown";
import ProfileDropdownContext from "../../contexts/profileDropDown";

const PageTemplate = ({ children }) => {
    const navigate = useNavigate();
    const handlePageChange = (page) => {
        navigate(`/${page}`);
    };

    const { isOpen } = useContext(ProfileDropdownContext); 

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const rootDivRef = useRef(null);

    // State to control the mobile drawer
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const toggleMobileNav = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    return (
        <div>
            <Header>
                {!isDesktop && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={toggleMobileNav}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
            </Header>
            {isOpen && <ProfileDropdown containerRef={rootDivRef} />}
            <LeftNavMenu 
                onPageChange={handlePageChange} 
                isMobileNavOpen={isMobileNavOpen} 
                toggleMobileNav={toggleMobileNav} 
            />
            <main
                style={{
                    marginLeft: isDesktop ? '240px' : '0',
                    flexGrow: 1,
                }}
            >
                {children}
            </main>
        </div>
    );
};

export default PageTemplate;
