import axios from 'axios';

// const baseUrl = process.env.REACT_APP_API_URL;
const baseUrl = "https://app.ittehaad.com/api";

// Function to get the current token from localStorage
export function getAuthToken() {
  return localStorage.getItem('token');
}


// Create an Axios instance
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Axios request interceptor to add the token only if it's available
api.interceptors.request.use(config => {
  const token = getAuthToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Add Authorization header only if token is not null
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Axios interceptor to catch 401 errors and trigger token refresh
api.interceptors.response.use(response => response);

export async function LoginUserApi(payload) {
  try {
    const response = await api.post('/login', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function SignUpUserApi(payload) {
  const response = await api.post('/signup', payload);
  return response.data;
}

export async function validateUserApi(payload) {
  try {
    const response = await api.post('/signup', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function RefreshTokenApi() {
  const payload = {
    grant_type: "refresh_token",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    refresh_token: localStorage.getItem('refreshToken'),
    scope: ""
  }
  const response = await api.post('/token/refresh', payload);
  return response.data;
}
export async function RevokeTokenApi() {
  const response = await api.post('/token/revoke');
  return response.data;
}

export async function GetAllPlans() {
  try {
    const response = await api.get('/plans');
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updateUserProfile(payload) {
  try {
    const response = await api.put('/user/profile', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function changeUserContact(payload) {
  try {
    const response = await api.post('/user/phone', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function changeUserPincode(payload) {
  try {
    const response = await api.put('/user/password', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function createPlan(payload, planID) {
  try {
    const response = await api.post(`/admin/policy/plan/${planID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updatePlan(payload, planID) {
  try {
    const response = await api.patch(`/admin/policy/plan/${planID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function deletePlan(payload, policyID) {
  try {
    const response = await api.delete(`/admin/policy/plan/${policyID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getUserProfile(payload) {
  const response = await api.get('/user/me', payload);
  return response.data;
}
export async function createPolicy(payload) {
  try {
    const response = await api.post('/admin/policy', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updatePolicy(payload, policyID) {
  try {
    const response = await api.patch(`/admin/policy/${policyID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function deletePolicy(payload, policyID) {
  try {
    const response = await api.delete(`/admin/policy/${policyID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function preparePayment(payload) {
  try {
    const response = await api.post(`/payment/prepare`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function userValidation(payload) {
  try {
    const response = await api.get(`/user/me`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function users(payload) {
  try {
    const response = await api.get(`/admin/users?limit=${payload.limit}&page=${payload.page}${payload.phoneNumber ? `&phone=${payload.phoneNumber}` : '' }`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function unsubscribePlan(payload) {
  try {
    const response = await api.post(`/subscription/cancel`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function accountLock(payload) {
  try {
    const response = await api.post(`/admin/user/lock-unlock/${payload.id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function accountBlocked(payload) {
  try {
    const response = await api.post(`/admin/user/block-unblock/${payload.id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function setConfigurations(payload) {
  try {
    const response = await api.post(`/admin/general/config`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getConfigurations() {
  try {
    const response = await api.get(`/app/settings`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
/**Apis for hospital form */
export async function setHospitals(payload) {
  try {
    const response = await api.post(`/admin/hospital`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updateHospitals(payload,hospitalID) {
  try {
    const response = await api.patch(`/admin/hospital/${hospitalID}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function deleteHospitals(payload,hospitalID) {
  try {
    const response = await api.delete(`/admin/hospital/${hospitalID}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getHospitals(payload) {
  try {
    const response = await api.get(`/hospitals`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
/**Api end for hospital form */

/**Api start for announcement Form */
export async function setAnnouncements(payload) {
  try {
    // Create FormData object
    const formData = new FormData();
    
    // Append each field from payload to the FormData object
    formData.append('title', payload.title);
    formData.append('description', payload.description);
    formData.append('start_date', payload.startDate);
    formData.append('end_date', payload.endDate);
    formData.append('policy_id', payload.selectedPolicy);
    if(payload.announcementID){
      formData.append('id', payload.announcementID);
    }
    if (payload.document) {
      formData.append('photo', payload.document); // Append file
    }

    // Make the API call with FormData
    const response = await api.post(`/admin/announcement`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure correct Content-Type
      },
    });

    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function updateAnnouncements(payload,announcementID) {
  try {
    // Create FormData object
    const formData = new FormData();
    
    // Append each field from payload to the FormData object
    formData.append('title', payload.title);
    formData.append('description', payload.description);
    formData.append('start_date', payload.startDate);
    formData.append('end_date', payload.endDate);
    formData.append('policy_id', payload.selectedPolicy);
    
    if (payload.document) {
      formData.append('photo', payload.document); // Append file
    }

    // Make the API call with FormData
    const response = await api.post(`/admin/announcement/${announcementID}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure correct Content-Type
      },
    });

    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function deleteAnnouncements(payload,announcementID) {
  try {
    const response = await api.delete(`/admin/announcement/${announcementID}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getAnnouncements(payload) {
  try {
    const response = await api.get(`/admin/announcements`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getAnnouncementsForUsers(payload) {
  try {
    const response = await api.get(`/announcements`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
/**Api end for anouncement form */

/**Api start for referral code */
export async function setReferrals(payload) {
  try {
    const response = await api.post(`/admin/referral`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updateReferrals(payload,referralID) {
  try {
    const response = await api.patch(`/admin/referral/${referralID}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function deleteReferrals(payload,referralID) {
  try {
    const response = await api.delete(`/admin/referral/${referralID}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getReferrals(payload) {
  try {
    const response = await api.get(`/admin/referrals?limit=${payload.limit}&page=${payload.page}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
/**Api end for referral code */

/**Api for recurring payments */
///admin/policy/2/pricing
export async function getPlanPricings(payload,policyID) {
  try {
    const response = await api.get(`/admin/policy/${policyID}/pricing`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function setPlanPricings(payload,policyID) {
  try {
    const response = await api.post(`/admin/policy/${policyID}/pricing`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function resetUserPin(payload,userPin) {
  try {
    const response = await api.post(`/admin/${userPin}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updateUserFromAdmin(payload,userID) {
  try {
    const response = await api.post(`/admin/user/edit/${userID}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function deleteUserFromAdmin(payload,userID) {
  try {
    const response = await api.delete(`/admin/user/delete/${userID}`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getTransactions(payload) {
  try {
    const response = await api.get(`/admin/transactions?limit=${payload.limit}&page=${payload.page}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getTransactionDetails(payload,transactionID) {
  try {
    const response = await api.get(`/admin/transaction/${transactionID}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function refundTransaction(payload,transactionID) {
  try {
    const response = await api.post(`/admin/transaction/${transactionID}/refund`,payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export default api;
