import React, { useState } from 'react';
import TitleAndLogo from '../../../../components/TitleAndLogo/titleAndLogo';
import { useToast } from '../../../../components/ToastNotification/ToastNotification';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { refundTransactionForAdmin } from '../../../../features/api.slice';
import { useAppDispatch } from '../../../../store';
import SpinnerPage from '../../../../components/Spinner/Spinner';
import { Typography } from '@mui/material';

const RefundModal = ({ onContinue, wrapperClass, refundData }) => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const showToast = useToast()
    const [amount, setAmount] = useState(refundData?.amount)
    const [pincode, setPincode] = useState('')

    function onClose() {
        onContinue()
        setAmount('')
        setPincode('')
    }
    function refundAmountHandle() {
        setIsLoading(true)
        if (!amount) {
            showToast('Please fill amount field', 3000)
            setIsLoading(false)
            return
        }
        if (!pincode) {
            showToast('Please fill pincode field', 3000)
            setIsLoading(false)
            return
        }
        if (Number(amount) > Number(refundData?.amount)) {
            showToast('Refund amount cannot be greater than the transaction amount', 3000)
            setIsLoading(false)
            return
        }
        const payload = {
            amount: amount,
            pincode: pincode,
            transactionID: refundData?.id,
            setIsLoading,
            showToast,
            onClose
        }
        dispatch(refundTransactionForAdmin(payload))
    }
    return (
        <div style={wrapperClass} className={wrapperClass ? wrapperClass : "otp-modal-wrapper"}>
            <div className="otp-modal">
                <div className="title-logo-container">
                    <TitleAndLogo logoColor="pink" />
                    <IconButton
                        className="close-button"
                        onClick={onClose} // Call the onClose function
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <Typography variant="h6" align="center" gutterBottom>
                    Process Refund
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Amount"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Pincode"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                />


                <button className={isLoading ? 'disabled-button continue-button' : 'continue-button'} disabled={isLoading} onClick={refundAmountHandle}>{isLoading ? 'Refunding...' : 'Refund'}</button>
            </div>
            <SpinnerPage loading={isLoading} />
        </div>
    );
};

export default RefundModal;
