import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import './leftnav.scss';

function LeftNavMenu({ onPageChange, isMobileNavOpen, toggleMobileNav }) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const userData = JSON.parse(localStorage.getItem('user'));

    // State to track the selected page
    const [selectedPage, setSelectedPage] = useState('dashboard');
    useEffect(() => {
        if (window.location.pathname === '/') {
            setSelectedPage('dashboard');
        }
        if (window.location.pathname === '/announcements') {
            setSelectedPage('announcements');
        }
        if (window.location.pathname === '/hospital') {
            setSelectedPage('hospital');
        }
        if (window.location.pathname === '/users') {
            setSelectedPage('users');
        }
        if (window.location.pathname === '/coverage-type') {
            setSelectedPage('coverage-type');
        }
        if (window.location.pathname === '/admin-configuration') {
            setSelectedPage('admin-configuration');
        }
        if (window.location.pathname === '/recurring-payment') {
            setSelectedPage('recurring-payment');
        }
        if (window.location.pathname === '/reports') {
            setSelectedPage('reports');
        }
        if (window.location.pathname === '/transactions') {
            setSelectedPage('transactions');
        }
    }, [])
    // Handle button click and set selected page
    function handlePageChange(page) {
        setSelectedPage(page);
        onPageChange(page); // Call the parent function to change the page
        if (!isDesktop) {
            toggleMobileNav(); // Close drawer on mobile after selection
        }
    };
    const drawerContent = (
        <List>
            <ListItem
                button="true"
                onClick={() => handlePageChange('dashboard')}
                className={`nav-item ${selectedPage === 'dashboard' ? 'active' : ''}`}
            >
                <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
                button="true"
                onClick={() => handlePageChange('announcements')}
                className={`nav-item ${selectedPage === 'announcements' ? 'active' : ''}`}
            >
                <ListItemText primary="Announcements" />
            </ListItem>
            <ListItem
                button="true"
                onClick={() => handlePageChange('hospital')}
                className={`nav-item ${selectedPage === 'hospital' ? 'active' : ''}`}
            >
                <ListItemText primary="Hospitals" />
            </ListItem>
            {userData?.admin &&
                <>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('users')}
                        className={`nav-item ${selectedPage === 'users' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('coverage-type')}
                        className={`nav-item ${selectedPage === 'coverage-type' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Coverage Type" />
                    </ListItem>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('admin-configuration')}
                        className={`nav-item ${selectedPage === 'admin-configuration' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Admin Configurations" />
                    </ListItem>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('recurring-payment')}
                        className={`nav-item ${selectedPage === 'recurring-payment' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Recurring Payments" />
                    </ListItem>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('transactions')}
                        className={`nav-item ${selectedPage === 'transactions' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Transactions" />
                    </ListItem>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('reports')}
                        className={`nav-item ${selectedPage === 'reports' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Reports" />
                    </ListItem>
                </>
            }
        </List>
    );

    return (
        <div className="left-nav-container">
            <Toolbar />
            <Drawer
                variant={isDesktop ? 'permanent' : 'temporary'}
                className={isDesktop ? "permanent-drawer" : ''}
                classes={{ paper: 'left-nav-drawer' }}
                open={isDesktop || isMobileNavOpen}
                onClose={toggleMobileNav} // Close the drawer when clicking outside on mobile
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile
                }}
            >
                {drawerContent}
            </Drawer>
        </div>
    );
};

export default LeftNavMenu;
