import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleAndLogo from '../../TitleAndLogo/titleAndLogo';
import SingleDigitInputs from '../SingleDigitInput/singleDigitInput';
import { useAppDispatch } from '../../../store';
import { callLogin, callSignUp } from '../../../features/api.slice'
import { useToast } from '../../ToastNotification/ToastNotification';
import Visibility from '@mui/icons-material/Visibility';  // Import visibility icon
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useContext } from 'react';
import PlanContext from '../../../contexts/PlanContext';
import './pinCode.scss'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
function PincodeModal(data) {
  const navigate = useNavigate();
  const [pin, setPin] = useState('')
  const [newPin, setNewPin] = useState('')
  const [repeatNewPin, setRepeatNewPin] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Added state for toggling visibility
  const mobileNumber = data?.data?.payload?.phone
  const referralCode = data?.data?.payload?.referral_code
  const userExists = data?.data?.signUpData?.body?.userExists
  const dispatch = useAppDispatch()

  async function handleOtpChange(e) {
    await setPin(e)
  }

  function handleNewPinChange(e) {
    setNewPin(e)
  }

  function handleRepeatNewPinChange(e) {
    setRepeatNewPin(e)
  }

  const showToast = useToast();
  useEffect(() => {
    if (newPin && repeatNewPin && repeatNewPin.length === 4 && newPin !== repeatNewPin) {
      showToast('Pincode does not match', 3000)
    }
    if (newPin.length < 4 || repeatNewPin.length < 4) {
      setIsDisabled(true)
    }
    if (newPin.length === 4 && repeatNewPin.length === 4 && newPin === repeatNewPin) {
      setIsDisabled(false)
      handleContinue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPin, repeatNewPin])
  useEffect(() => {
    if (pin !== '' && pin.length === 4) {
      handleContinue()
      setIsDisabled(false)
    }
    if (pin === '' || pin.length < 4) {
      setIsDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin])
  const { setIsLoggedIn } = useContext(PlanContext);

  async function handleContinue() {
    setIsLoading(true)
    if (userExists) {
      const payload = {
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: mobileNumber,
        password: pin,
        scope: "",
        navigate,
        showToast,
        setIsLoading,
        setIsLoggedIn

      }
      await dispatch(callLogin(payload))
    }
    else {
      const payload = {
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        phone: mobileNumber,
        password: newPin,
        code: '',
        validate: 0,
        referral_code: referralCode,
        navigate,
        showToast,
        setIsLoading,
      }
      await dispatch(callSignUp(payload))
    }
  };
  function handleWhatsAppRedirect() {
    const phoneNumber = '923000779339'; // Your specific phone number in international format
    const message = encodeURIComponent('Reset Pincode');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank'); // Redirect to WhatsApp with the message
    setDialogOpen(false); // Close the dialog after proceeding
  };
  function handleClose() {
    setDialogOpen(false)
  }
  function handleForgotPincodeClick() {
    setDialogOpen(true)
  }
  return (
    <div className="otp-modal-wrapper">
      <div className="otp-modal">
        <div className="title-logo-container">
          <TitleAndLogo logoColor="pink" />
        </div>
        {userExists ? (
          <>
            <h2>Enter pincode</h2>
            <p className='d-flex justify-space-between'>
              Please enter your pin
              <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? <VisibilityOff fontSize='small' style={{ cursor: 'pointer' }} /> : <Visibility fontSize='small' style={{ cursor: 'pointer' }} />}
              </span>
            </p>
            <SingleDigitInputs onChange={handleOtpChange} showPassword={showPassword} />
            <div className="forgot-pincode"><p className="forgot-pincode-text" onClick={handleForgotPincodeClick}>
              Forgot Pincode?
            </p></div>
          </>
        ) : (
          <>
            <h2>Set pincode</h2>
            <p className='d-flex justify-space-between'>
              Set a 4-digit pincode
              <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? <VisibilityOff fontSize='small' style={{ cursor: 'pointer' }} /> : <Visibility fontSize='small' style={{ cursor: 'pointer' }} />}
              </span>
            </p>
            <SingleDigitInputs onChange={handleNewPinChange} showPassword={showPassword} />

            <h2>Confirm pincode</h2>
            <p className='d-flex justify-space-between'>
              Re-enter the pincode

            </p>
            <SingleDigitInputs onChange={handleRepeatNewPinChange} showPassword={showPassword} />
          </>
        )}
        <button
          className={isLoading || isDisabled ? 'disabled-button continue-button' : "continue-button"}
          disabled={isLoading || isDisabled}
          onClick={handleContinue}
        >
          {isLoading ? 'Loading...' : 'Continue'}
        </button>
      </div>
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>Forgot Pincode?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Send whatsapp on 0300-0779339 from the registered number with this message "Reset Pincode"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleWhatsAppRedirect} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
};

export default PincodeModal;
