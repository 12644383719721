import React, { useState, useEffect } from 'react';
import './UserEdit.scss';
import TitleAndLogo from '../../../../components/TitleAndLogo/titleAndLogo';
import { useToast } from '../../../../components/ToastNotification/ToastNotification';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { updateUserForAdmin } from '../../../../features/api.slice';
import { useAppDispatch } from '../../../../store';

const EditUser = ({ onContinue, wrapperClass, EditUserData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const showToast = useToast();
    // Define all user input fields
    const [firstName, setFirstName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [cnic, setCNIC] = useState('');

    // Initialize values if editing an existing user
    useEffect(() => {
        if (EditUserData) {
            setFirstName(EditUserData.first_name || '');
            setPhone(EditUserData.phone || '');
            setEmail(EditUserData.email || '');
            setPassword(EditUserData.password || '');
            setIsEnabled(EditUserData.enabled || false);
            setIsVerified(EditUserData.verified || false);
            setCNIC(EditUserData.cnic || '');
        }
    }, [EditUserData]);

    // Function to update an existing user
    function updateUser() {
        setIsLoading(true);
        const userID = EditUserData.id;
        const payload = {
            first_name: firstName,
            email: email,
            phone: phone,
            cnic: cnic,
            password: password,
            enabled: isEnabled ? "1" : "0",
            verified: isVerified ? "1" : "0",
            showToast,
            userID,
            setIsLoading,
            onContinue,
        };
        dispatch(updateUserForAdmin(payload));
    }

    // Function to handle closing the modal
    function onClose() {
        onContinue();
        setFirstName('');
        setPhone('');
        setEmail('');
        setPassword('');
        setIsEnabled(false);
        setIsVerified(false);
        setCNIC('');
    }

    return (
        <div style={wrapperClass} className={wrapperClass || "otp-modal-wrapper"}>
            <div className="otp-modal">
                <div className="title-logo-container">
                    <TitleAndLogo logoColor="pink" />
                    <IconButton
                        className="close-button"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>

                {/* User input fields */}
                <TextField
                    margin="dense"
                    label="First Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Last Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                {/* Policy inputs */}
                <TextField
                    margin="dense"
                    label="CNIC"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={cnic}
                    onChange={(e) => setCNIC(e.target.value)}
                />

                {/* Checkboxes for Enabled and Verified */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isEnabled}
                            onChange={(e) => setIsEnabled(e.target.checked)}
                        />
                    }
                    label="Enabled"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isVerified}
                            onChange={(e) => setIsVerified(e.target.checked)}
                        />
                    }
                    label="Verified"
                />

                {/* Submit Button */}
                <button
                    className={`continue-button ${isLoading ? 'disabled-button ' : ''}`}
                    disabled={isLoading}
                    onClick={updateUser}
                >
                    {isLoading ? 'Updating...' : 'Update'}
                </button>
            </div>
        </div>
    );
};

export default EditUser;
