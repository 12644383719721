import React, { useState } from 'react';
import { useToast } from '../../../../components/ToastNotification/ToastNotification';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, TextField } from '@mui/material';
import { setPlanPricingData } from '../../../../features/api.slice';
import { useAppDispatch } from '../../../../store';
import './RecurringPaymentPopup.scss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const AddRecurringPayment = ({ onContinue, wrapperClass, plans, loadNewDatainTable, activeTab }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [deductionDates, setDeductionDates] = useState({});
    const [amounts, setAmounts] = useState({});  // State to track amount inputs
    const dispatch = useAppDispatch();
    const showToast = useToast();

    const handleAmountChange = (planId, value) => {
        setAmounts((prev) => ({
            ...prev,
            [planId]: value,
        }));
    };

    const handleDeductionDateChange = (planId, newValue) => {
        setDeductionDates((prev) => ({
            ...prev,
            [planId]: newValue,
        }));
    };

    // Function to check if all amounts and deduction dates are filled
    const isFormValid = () => {
        if (plans?.plans.length > 0) {
            return plans?.plans?.every(plan =>
                amounts[plan.id] && deductionDates[plan.id]
            );
        }
        return false;
    };

    const createPlans = () => {
        setIsLoading(true);
        const planData = plans?.plans?.map((plan) => ({
            plan_id: plan.id,
            price: amounts[plan.id] || plan.amount || 0, // Use updated or default amount
            deduction_date: dayjs(deductionDates[plan.id])?.format('YYYY-MM-DD') || null, // Format date if available
        }));

        const payload = {
            plans: planData,
            showToast,
            policyID: activeTab,
            onClose,
        };
        // Send the API request
        dispatch(setPlanPricingData(payload));
    };

    const onClose = () => {
        onContinue();
        loadNewDatainTable();
        setDeductionDates({});
        setAmounts({});
    };
    const tomorrow = dayjs().add(1, 'day');
    return (
        <div style={wrapperClass} className={wrapperClass ? wrapperClass : "otp-modal-wrapper"}>
            <div className="recurring-payment-modal">
                <div className="recurring-title-container">
                    <h2 className="modal-title">Update Recurring Payments</h2>
                    <IconButton
                        className="recurring-close-button"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>

                {plans?.plans?.length > 0 ? (
                    plans?.plans?.map((plan) => (
                        <Grid container spacing={2} alignItems="center" key={plan.id}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    margin="dense"
                                    type="text"
                                    variant="outlined"
                                    value={plan.title}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    className="amount-field"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    margin="dense"
                                    label="Amount"
                                    type="number"
                                    variant="outlined"
                                    value={amounts[plan.id] || plan.amount || ''}
                                    onChange={(e) => handleAmountChange(plan.id, e.target.value)}
                                    fullWidth
                                    className="amount-field"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Deduction Date"
                                        value={deductionDates[plan.id] || null}
                                        onChange={(newValue) => handleDeductionDateChange(plan.id, newValue)}
                                        minDate={tomorrow} // Set the minimum date to tomorrow
                                        renderInput={(params) => (
                                            <TextField {...params} fullWidth required sx={{ mt: 1 }} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <p className="no-plans-message">No plans available for this.</p>
                )}

                <div className='button-container'>
                    <button
                        className={`recurring-continue-button ${isLoading || !isFormValid() ? 'disabled-button' : ''}`}
                        disabled={isLoading || !isFormValid()}
                        onClick={createPlans}
                    >
                        {isLoading ? 'Saving...' : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddRecurringPayment;
