import React, { useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Box,
    TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './Reports.scss';
import axios from 'axios';
import dayjs from 'dayjs';
import { useToast } from '../../../components/ToastNotification/ToastNotification';
import SpinnerPage from '../../../components/Spinner/Spinner';
function Reports() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const showToast = useToast()
    function openReport(report) {
        setIsLoadingReport(true);
        if (!toDate) {
            showToast('Please select To date', 3000);
            setIsLoadingReport(false);
            return;
        }
        if(!fromDate) {
            showToast('Please select From date', 3000);
            setIsLoadingReport(false);
            return;
        }
        if(fromDate > toDate) {
            showToast('From date should be less than To date', 3000);
            setIsLoadingReport(false);
            return;
        }
        
        const baseUrl = "https://app.ittehaad.com/api";
        const apiEndPoint = report.endPoint;
        let fileName = report.reportName;
        const startDate = dayjs(fromDate).format('YYYY-MM-DD');
        const endDate = dayjs(toDate).format('YYYY-MM-DD');
        axios({
            url: baseUrl + apiEndPoint + "?startDate=" + startDate + "&endDate=" + endDate, // your URL
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); // any other extension
                document.body.appendChild(link);
                link.click();
                setIsLoadingReport(false);
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });

    }

    function resetFilter() {
        setFromDate(null);
        setToDate(null);
    }

    const reports = [
        { MenuID: 11, MenuName: 'Daily Login Report', endPoint: '/admin/user/logins', reportName: 'DailyLoginReport.xlsx' },
        { MenuID: 12, MenuName: 'Complete/Incomplete Subscriptions', endPoint: '/admin/user/subscriptions', reportName: 'CompleteIncompleteSubscriptions.xlsx' },
    ];
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                backgroundColor: '#f5f5f5',
                minHeight: '100vh',
            }}
        >
            <div className="policy-header align-center">
                <h2 className="header-title">Reports</h2>
            </div>
            <div className='filter-container'>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center'
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="From Date"
                            value={fromDate}
                            onChange={(newValue) => setFromDate(newValue)}
                            inputFormat="DD-MM-YYYY"
                            format="DD-MM-YYYY" 
                            maxDate={dayjs()} 
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    style={{ width: '180px' }} // Set a fixed width here
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="To Date"
                            value={toDate}
                            onChange={(newValue) => setToDate(newValue)}
                            inputFormat="DD-MM-YYYY" 
                            format="DD-MM-YYYY" 
                            maxDate={dayjs()} 
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    style={{ width: '180px' }} // Set a fixed width here
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
                <button className="filter-button" onClick={resetFilter}>Reset Filter</button>
            </div>
            <div className="report-container">
                <Box className="report-wrapper">

                    <List dense className="report-list">
                        {reports.map((report) => (
                            <ListItem key={report.MenuID} disablePadding>
                                <ListItemText
                                    primary={report.MenuName}
                                    onClick={() => openReport(report)}
                                    sx={{ cursor: 'pointer' }}
                                />
                            </ListItem>
                        ))}
                    </List>

                </Box>
            </div>
            <SpinnerPage loading={isLoadingReport} />
        </Box>
        
    );
}

export default Reports;
