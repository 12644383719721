import React, { useEffect, useState, useContext } from 'react';
import {
    TextField,
    Typography,
    Grid2,
    Box,
    Switch,
    Paper,
    FormControlLabel,
    Tooltip,
} from '@mui/material';
import { useAppDispatch } from '../../../../store';
import { getAdminConfigurations, setAdminConfigurations } from '../../../../features/api.slice';
import { useToast } from '../../../../components/ToastNotification/ToastNotification';
import AdminContext from '../../../../contexts/AdminConfigs';
import './AdminConfigs.scss'

function AdminConfigs() {
    const showToast = useToast();
    const { setAdminConfigs, setLoadingConfigs } = useContext(AdminContext);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValues, setInputValues] = useState({
        authLockoutBlock: '',
        authLockoutDuration: '',
        authLockoutLimit: '',
        authSignupBlock: '',
        authSignupDuration: '',
        authSignupLimit: '',
    });
    const [newsPopup, setNewsPopup] = useState({
        generalAuthSignupPopup: false,
        generalAuthSignupContent: '',
        generalUserSignupPopup: false,
        generalUserSignupContent: '',
        generalSubscribedPopup: false,
        generalSubscribedContent: '',
    });

    const dispatch = useAppDispatch();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    useEffect(() => {
        const payload = {
            setInputValues,
            setNewsPopup,
            setAdminConfigs,
            setLoadingConfigs,
        };
        dispatch(getAdminConfigurations(payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const payload = {
            setInputValues,
            setNewsPopup,
            setIsLoading,
            setAdminConfigs,
            setLoadingConfigs,
            showToast,
            authLockoutLimit: inputValues?.authLockoutLimit,
            authLockoutDuration: inputValues?.authLockoutDuration,
            authLockoutBlock: inputValues?.authLockoutBlock,
            authSignupLimit: inputValues?.authSignupLimit,
            authSignupDuration: inputValues?.authSignupDuration,
            authSignupBlock: inputValues?.authSignupBlock,
            generalAuthSignupPopup: newsPopup?.generalAuthSignupPopup ? 1 : 0,
            generalUserSignupPopup: newsPopup?.generalUserSignupPopup ? 1 : 0,
            generalSubscribedPopup: newsPopup?.generalSubscribedPopup ? 1 : 0,
            generalAuthSignupContent: newsPopup?.generalAuthSignupContent,
            generalUserSignupContent: newsPopup?.generalUserSignupContent,
            generalSubscribedContent: newsPopup?.generalSubscribedContent,
        };
        dispatch(setAdminConfigurations(payload));
    };

    const adminConfigs = [
        {
            label: "Limit Before Block",
            name: "authLockoutBlock",
            description: "Defines the number of attempts before blocking the user.",
        },
        {
            label: "Lockout Duration (hours)",
            name: "authLockoutDuration",
            description: "Specifies how long the user will be locked out.",
        },
        {
            label: "Limit Before Lockout",
            name: "authLockoutLimit",
            description: "The number of failed attempts before locking the user out.",
        },
        {
            label: "Limit Before Sign Up Block",
            name: "authSignupBlock",
            description: "Defines the maximum number of sign-up attempts before blocking.",
        },
        {
            label: "Limit Sign Up Duration (hours)",
            name: "authSignupDuration",
            description: "The time window to sign up before the session expires.",
        },
        {
            label: "Sign Up Limit",
            name: "authSignupLimit",
            description: "Maximum sign-ups allowed per user.",
        },
    ];

    const newsPopupSettings = [
        {
            label: "Not Signed Up",
            content: newsPopup.generalAuthSignupContent,
            popupState: newsPopup.generalAuthSignupPopup,
            setContent: (value) => setNewsPopup({ ...newsPopup, generalAuthSignupContent: value }),
            setPopupState: () => setNewsPopup({ ...newsPopup, generalAuthSignupPopup: !newsPopup.generalAuthSignupPopup }),
            tooltip: "This popup appears when a user is not signed up yet.",
        },
        {
            label: "Signed Up No Plan",
            content: newsPopup.generalUserSignupContent,
            popupState: newsPopup.generalUserSignupPopup,
            setContent: (value) => setNewsPopup({ ...newsPopup, generalUserSignupContent: value }),
            setPopupState: () => setNewsPopup({ ...newsPopup, generalUserSignupPopup: !newsPopup.generalUserSignupPopup }),
            tooltip: "This popup appears for users who are signed up but do not have a plan.",
        },
        {
            label: "Subscribed",
            content: newsPopup.generalSubscribedContent,
            popupState: newsPopup.generalSubscribedPopup,
            setContent: (value) => setNewsPopup({ ...newsPopup, generalSubscribedContent: value }),
            setPopupState: () => setNewsPopup({ ...newsPopup, generalSubscribedPopup: !newsPopup.generalSubscribedPopup }),
            tooltip: "This popup appears for users who are subscribed.",
        },
    ];

    return (
        <Paper elevation={1} sx={{ padding: 5 }}>
            <Box component="form" onSubmit={handleSubmit}>
                <Grid2 container spacing={2}>
                    {adminConfigs?.map(({ label, name, description }) => (
                        <Grid2 xs={12} sm={6} md={4} key={`admin-configs--${name}`}>
                            <Box>
                                <Typography variant="subtitle1" className="field-label" sx={{ display: 'flex', alignItems: 'center' }}>
                                    {label}
                                    <Tooltip title={description} arrow sx={{ typography: 'body2', fontSize: '2rem' }}>
                                        <span style={{ cursor: 'pointer', marginLeft: 4, transition: 'color 0.3s', color: 'gray' }}>
                                            ❓
                                        </span>
                                    </Tooltip>
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name={name}
                                    value={inputValues[name]}
                                    onChange={handleInputChange}
                                    sx={{ marginTop: 1 }} // Add margin for spacing
                                />
                            </Box>
                        </Grid2>
                    ))}
                </Grid2>
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" className='news-header'>News Popup Settings</Typography>
                    <Grid2 container spacing={2}>
                        {newsPopupSettings.map(({ label, content, popupState, setContent, setPopupState, tooltip }) => (
                            <Grid2 xs={12} sm={6} key={label}>
                                <Box>
                                    <Typography variant="subtitle1" className="field-label margin-bottom--10" sx={{ display: 'flex', alignItems: 'center' }}>
                                        {label}
                                        <Tooltip title={tooltip} arrow sx={{ typography: 'body2', fontSize: '1rem' }}>
                                            <span style={{ cursor: 'pointer', marginLeft: 4, transition: 'color 0.3s', color: 'gray' }}>
                                                ❓
                                            </span>
                                        </Tooltip>
                                    </Typography>
                                    <TextField
                                        label={`Content for ${label}`}
                                        fullWidth
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!!popupState}
                                                onChange={setPopupState}
                                            />
                                        }
                                        label="Show Popup"
                                    />
                                </Box>
                            </Grid2>
                        ))}
                    </Grid2>
                </Box>
                <Box textAlign="right" mt={3}>
                    <button className={isLoading ? 'disabled-button policy-button' : "policy-button"} type="submit" disabled={isLoading}>
                        {isLoading ? 'Updating Configuration...' : 'Update Configurations'}
                    </button>
                </Box>
            </Box>
        </Paper>
    );
}

export default AdminConfigs;
