import React from "react";
import "./NewsPopup.scss"; // Import the CSS file

const NewsPopup = ({ newsText, onClose, LoginButton }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        
        <p>{newsText}</p>
        {LoginButton ?
          <button className="action-button" onClick={onClose}>Continue to Login</button>
          : <button className="action-button" onClick={onClose}>Close</button>
        }
      </div>
    </div>
  );
};

export default NewsPopup;
