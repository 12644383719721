import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid2, Paper } from '@mui/material';
import './HospitalForm.scss';
import HospitalsTable from './HospitalTable';
import { useAppDispatch } from '../../../../store';
import { deleteHospitalsData, getHospitalsData, setHospitalsData, updateHospitalsData } from '../../../../features/api.slice';
import { useToast } from '../../../../components/ToastNotification/ToastNotification';
import ConfirmationDialog from '../../../../components/DialogBox/Confirmation';
const HospitalForm = () => {
  const [hospitalData, setHospitalData] = useState({
    id: null, // Track the ID for editing
    title: '',
    city: '',
    phone: '',
    email: '',
    website: '',
    onboarding_date: '',
  });
  const [hospitalsForTable, setHospitalsForTable] = useState([]); // State to hold the list of hospitals
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const dispatch = useAppDispatch();
  const showToast = useToast();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setHospitalData({
      ...hospitalData,
      [name]: value,
    });
  };
  useEffect(() => {
    const payload = {
      setHospitalsForTable
    }
    dispatch(getHospitalsData(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!hospitalData.id) {
      const payload = {
        title: hospitalData.title,
        city: hospitalData.city,
        phone: hospitalData.phone,
        email: hospitalData.email,
        website: hospitalData.website,
        onboarding_date: hospitalData.onboarding_date,
        showToast,
        setIsLoading,
        setHospitalsForTable
      }
      dispatch(setHospitalsData(payload));
    }
    else if (hospitalData?.id) {
      const payload = {
        hospitalID: hospitalData.id,
        title: hospitalData.title,
        city: hospitalData.city,
        phone: hospitalData.phone,
        email: hospitalData.email,
        website: hospitalData.website,
        onboarding_date: hospitalData.onboarding_date,
        showToast,
        setIsLoading,
        setHospitalsForTable
      }
      dispatch(updateHospitalsData(payload));
    }
    // Reset form after success
    setHospitalData({
      id: null,
      title: '',
      city: '',
      phone: '',
      email: '',
      website: '',
      onboarding_date: '',
    });

  };

  const handleEdit = (hospital) => {
    setHospitalData({ ...hospital });
  };
  function handleDelete(id) {
    setHospitalData({
      ...hospitalData,
      id: id
    });
    setOpenConfirmationDialog(true);
  };
  function handleClose() {
    setOpenConfirmationDialog(false);
  }
  function deleteHospitalData() {
    const payload = {
      hospitalID: hospitalData.id,
      showToast,
      setIsLoading,
      setHospitalsForTable,
      setOpenConfirmationDialog
    }
    dispatch(deleteHospitalsData(payload));

  }
  return (
    <>
      <Paper elevation={1} sx={{ padding: 5 }}>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Hospital Name"
                name="title"
                value={hospitalData.title}
                onChange={handleChange}
                required
              />
            </Grid2>
            <Grid2 xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={hospitalData.city}
                onChange={handleChange}
                required
              />
            </Grid2>
            <Grid2 xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={hospitalData.phone}
                onChange={handleChange}
                required
                type="tel"
              />
            </Grid2>
            <Grid2 xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={hospitalData.email}
                onChange={handleChange}
                required
                type="email"
              />
            </Grid2>
            <Grid2 xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Website"
                name="website"
                value={hospitalData.website}
                onChange={handleChange}
                required
              />
            </Grid2>
            <Grid2 xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Onboarding Date"
                name="onboarding_date"
                value={hospitalData.onboarding_date}
                onChange={handleChange}
                required
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid2>
          </Grid2>
          <Grid2 xs={12} md={4} className='button-container'>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={isLoading ? 'disabled-button save-button' : 'save-button'}
              disabled={isLoading}
              sx={{
                padding: '10px 0',
                fontWeight: 'bold',
                borderRadius: '8px',
                backgroundColor: '#E44E80',
              }}
            >
              {isLoading && hospitalData?.id ? 'Updating...' : hospitalData?.id ? 'Update' : isLoading && !hospitalData?.id ? 'Saving...' : 'Save'}
            </Button>
          </Grid2>
        </form>
      </Paper>
      <Paper elevation={1} sx={{ padding: 5 }}>
        <HospitalsTable hospitals={hospitalsForTable} onEdit={handleEdit} onDelete={handleDelete} />
      </Paper>
      <ConfirmationDialog
        title="Delete Hospital Data"
        open={openConfirmationDialog}
        onClose={handleClose}
        onConfirm={deleteHospitalData}
        message="Are you sure you want to delete this hospital data?"
      />
    </>
  );
};

export default HospitalForm;
