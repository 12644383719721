import React, { useContext } from 'react';
import './header.scss';
import ProfileDropdownContext from '../../contexts/profileDropDown';

function Header({ children }) { // Accept children as a prop
    const { isOpen, setIsOpen } = useContext(ProfileDropdownContext);

    function handleClick() {
        setIsOpen(!isOpen);
    };

    const userData = JSON.parse(localStorage.getItem('user')) || {};

    function getInitials(name) {
        const nameParts = name?.trim()?.split(' ').filter(part => part?.length > 0);
        const initials = nameParts?.map(part => part[0].toUpperCase()).join('');
        return initials;
    }

    return (
        <header className="header">
            <div className="left-container">
                {children} {/* This is where the hamburger icon will go */}
                <img src='/images/newLogo.jpeg' alt="Logo" className="logo" />

                {/*  <img src='images/logo.png' alt="Logo 1" className="logo" />
                <img src='images/header/ittehad_name.png' alt="Logo 2" className="logo" /> */}
            </div>
            <div onClick={handleClick} className="right-container">
                <div className="circle">
                    {getInitials(userData?.first_name)}
                </div>
                <span className="full-name">{userData?.first_name || userData?.phone}</span>
            </div>
        </header>
    );
};

export default Header;
