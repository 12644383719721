import React, { useState, useEffect, useContext } from 'react';
import './dashboard.scss';
import PolicyCover from '../PolicyCover/PolicyCover';
import { useAppDispatch, useAppSelector } from '../../store';
import { GetPlans } from '../../features/api.slice';
import { allPlansData } from '../../features/api.selector';
import PlanContext from '../../contexts/PlanContext';
import NewsPopup from '../NewsPopup/NewsPopup';
import AdminContext from '../../contexts/AdminConfigs';

function Dashboard() {
    const [activeTab, setActiveTab] = useState(1);
    const { setAllowPlanChange } = useContext(PlanContext);
    const { adminConfigs } = useContext(AdminContext);
    const [showPopup, setShowPopup] = useState(false); // Default to false
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Check if popup has already been shown
        const popupShown = localStorage.getItem('popupShown');
        if (!popupShown) {
            setShowPopup(true); // Show popup if it hasn't been shown
        }

        // Fetch plans when component mounts
        dispatch(GetPlans());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const plans = useAppSelector(allPlansData);

    function setTabs(id) {
        setActiveTab(id);
    }

    const selectedPolicy = plans?.body?.response?.find(item => item.id === activeTab);
    const tabs = plans?.body?.response?.filter(item => item.status === 'active');

    function changeTab(id) {
        setTabs(id);
        setAllowPlanChange(false);
    }

    function handleClosePopup() {
        setShowPopup(false);
        localStorage.setItem('popupShown', 'true'); // Set flag in local storage when popup is closed
    }
    return (
        <div className="dashboard-component">
            <div className="dashboard-content">
                <h2 style={{ marginTop: '0px' }}>{selectedPolicy?.active ? 'Your Plan' : 'Select plan'}</h2>
                <div className="tab-menu">
                    {tabs?.map((tab) => (
                        <button
                            key={tab.id}
                            className={activeTab === tab?.id ? 'active' : ''}
                            onClick={() => changeTab(tab?.id)}
                        >
                            {tab?.title}
                        </button>
                    ))}
                </div>
                {activeTab && <PolicyCover selectedPolicy={selectedPolicy} />}
            </div>
            {showPopup && adminConfigs?.generalUserSignupPopup && !plans?.body?.subscription ? (
                <NewsPopup
                    newsText={adminConfigs?.generalUserSignupContent}
                    onClose={handleClosePopup}
                />
            ) : ''}
            {showPopup && adminConfigs?.generalSubscribedPopup && plans?.body?.subscription ? (
                <NewsPopup
                    newsText={adminConfigs?.generalSubscribedContent}
                    onClose={handleClosePopup}
                />
            ) : ''}
        </div>
    );
};

export default Dashboard;
