import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerExist: null,
  userLoginData: null,
  userSignUpData: null,
  allPlansData: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    callLogin: (_state, action) => {
      return action.payload.query;
    },
    loginData: (state, action) => {
      state.userLoginData = action.payload;
    },
    callSignUp: (_state, action) => {
      return action.payload.query;
    },
    signUpData: (state, action) => {
      state.userSignUpData = action.payload;
    },
    callValidateUser: (_state, action) => {
      return action.payload.query;
    },
    GetPlans: (_state, action) => {
    },
    AllPlansData: (state, action) => {
      state.allPlansData = action.payload;
    },
    updateUser: (_state, action) => {
      return action.payload.query;
    },
    updateContact: (_state, action) => {
      return action.payload.query;
    },
    changePincode: (_state, action) => {
      return action.payload.query;
    },
    createPlanForPolicy: (_state, action) => {
      return action.payload.query;
    },
    getUser: (_state, action) => {
      return action.payload.query;
    },
    createPolicyCover: (_state, action) => {
      return action.payload.query;
    },
    updateUserPolicy: (_state, action) => {
      return action.payload.query;
    },
    deleteUserPolicy: (_state, action) => {
      return action.payload.query;
    },
    updateUserPlan: (_state, action) => {
      return action.payload.query;
    },
    deleteUserPlan: (_state, action) => {
      return action.payload.query;
    },
    clearStates: (state) => {
      state.customerExist = null;
      state.userLoginData = null;
      state.userSignUpData = null;
      state.allPlansData = null;
    },
    prepareUserPayment: (_state, action) => {
      return action.payload.query;
    },
    validateUser: (_state, action) => {
      return action.payload.query;
    },
    getUsers: (_state, action) => {
      return action.payload.query;
    },
    unsubscribeUserPlan: (_state, action) => {
      return action.payload.query;
    },
    lockUserAccount: (_state, action) => {
      return action.payload.query;
    },
    blockUserAccount: (_state, action) => {
      return action.payload.query;
    },
    setAdminConfigurations: (_state, action) => {
      return action.payload.query;
    },
    getAdminConfigurations: (_state, action) => {
      return action.payload.query;
    },
    setHospitalsData: (_state, action) => {
      return action.payload.query
    },
    getHospitalsData: (_state, action) => {
      return action.payload.query
    },
    updateHospitalsData: (_state, action) => {
      return action.payload.query
    },
    deleteHospitalsData: (_state, action) => {
      return action.payload.query
    },
    getAnnouncementsData: (_state, action) => {
      return action.payload.query
    },
    setAnnouncementsData: (_state, action) => {
      return action.payload.query
    },
    updateAnnouncementsData: (_state, action) => {
      return action.payload.query
    },
    deleteAnnouncementsData: (_state, action) => {
      return action.payload.query
    },
    setReferralCode: (_state, action) => {
      return action.payload.query
    },
    getReferralsData: (_state, action) => {
      return action.payload.query
    },
    updateReferralsData: (_state, action) => {
      return action.payload.query
    },
    deleteReferralsData: (_state, action) => {
      return action.payload.query
    },
    getAnnouncementsForUsersData: (_state, action) => {
      return action.payload.query
    },
    getPlanPricingData: (_state, action) => {
      return action.payload.query
    },
    setPlanPricingData: (_state, action) => {
      return action.payload.query
    },
    resetPincodeForUser: (_state, action) => {
      return action.payload.query
    },
    updateUserForAdmin: (_state, action) => {
      return action.payload.query
    },
    deleteUserForAdmin: (_state, action) => {
      return action.payload.query
    },
    getTransactionsForAdmin: (_state, action) => {
      return action.payload.query
    },
    getTransactionDetailsForAdmin: (_state, action) => {
      return action.payload.query
    },
    refundTransactionForAdmin: (_state, action) => {
      return action.payload.query
    },
    revokeUserToken: (_state, action) => {
      return action.payload.query
    },
  },
});

export const {
  callLogin,
  revokeUserToken,
  getTransactionsForAdmin,
  refundTransactionForAdmin,
  getTransactionDetailsForAdmin,
  updateUserForAdmin,
  deleteUserForAdmin,
  resetPincodeForUser,
  setPlanPricingData,
  getPlanPricingData,
  getAnnouncementsForUsersData,
  setAnnouncementsData,
  updateAnnouncementsData,
  setAdminConfigurations,
  deleteAnnouncementsData,
  getAdminConfigurations,
  updateHospitalsData,
  updateReferralsData,
  deleteReferralsData,
  deleteHospitalsData,
  setReferralCode,
  getReferralsData,
  getAnnouncementsData,
  getHospitalsData,
  blockUserAccount,
  validateUser,
  getUsers,
  updateUserPlan,
  setHospitalsData,
  prepareUserPayment,
  loginData,
  createPlanForPolicy,
  deleteUserPolicy,
  createPolicyCover,
  updateUserPolicy,
  clearStates,
  callSignUp,
  lockUserAccount,
  getUser,
  signUpData,
  callValidateUser,
  GetPlans,
  AllPlansData,
  updateUser,
  updateContact,
  changePincode,
  createPlanForPoliy,
  deleteUserPlan,
  unsubscribeUserPlan,
} = dataSlice.actions;
export default dataSlice.reducer;
