import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
const TransactionModal = ({ onContinue, wrapperClass, dataToShow }) => {
    function onClose() {
        onContinue();
    }
    return (
        <div style={wrapperClass} className={wrapperClass ? wrapperClass : "otp-modal-wrapper"}>
            <div className="otp-modal">
                <div className="transaction-details">
                    <Typography variant="h6" align="center" gutterBottom>
                        Transaction Details
                    </Typography>
                    <Divider />

                    <Box sx={{ my: 2, px: 3 }}>
                        <Typography variant="body1">
                            <strong>Status:</strong> {dataToShow.pp_Status}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Response Code:</strong> {dataToShow.pp_ResponseCode}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Response Message:</strong> {dataToShow.pp_ResponseMessage}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Payment Response Code:</strong> {dataToShow.pp_PaymentResponseCode}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Payment Message:</strong> {dataToShow.pp_PaymentResponseMessage}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Retrieval Reference No:</strong> {dataToShow.pp_RetrievalReferenceNo}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Settlement Date:</strong> {dataToShow.pp_SettlementDate}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Authorization Code:</strong> {dataToShow.pp_AuthCode}
                        </Typography>
                    </Box>

                    <Divider />
                    <button className="continue-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default TransactionModal;
