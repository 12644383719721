import React, { useState,useContext } from 'react';
import './planCard.scss';
import { useNavigate } from 'react-router-dom';
import UnsubscribeModal from '../Modals/unsubscribeModal';
import SuccessfulUnsubscriptionModal from '../Modals/successfulUnsubscriptionModal';
import PlanContext from '../../contexts/PlanContext';

const PlanCard = (subscribedUserPlan) => {
    const navigate = useNavigate()
    const { setAllowPlanChange } = useContext(PlanContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessfulUnsubscriptionModalOpen, setIsSuccessfulUnsubscriptionModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleSuccessfulUnubscriptionCloseModal = () => {
        setIsSuccessfulUnsubscriptionModalOpen(false);
    };
    const handleConfirmModal = () => {
        setIsModalOpen(false);
        setIsSuccessfulUnsubscriptionModalOpen(true);
    };
    const planDetails = subscribedUserPlan?.subscribedUserPlan
    function changePlan(){
        navigate('/dashboard')
        setAllowPlanChange(true)
    }
    const images = [
        { name: 'Bronze',image: '/images/dashboard/bronze.png' },
        { name: 'Silver',image: '/images/dashboard/silver.png'},
        { name: 'Gold', image: '/images/dashboard/gold.png' },
        { name: 'Platinum', image: '/images/dashboard/platinum.png' },
    ]
    const planImage = images.find((image) => image.name === planDetails?.title).image;
    return (
        <div className="plan-card">
            <div className="plan-card-header">
                <div className="plan-info">
                    <img src={planImage || '/images/dashboard/bronze.png'} alt={planDetails?.name} className="plan-avatar" />
                    <div className="plan-details">
                        <span className="plan-name">{planDetails?.title}</span>
                    </div>
                </div>
                <div className="plan-price-info">
                    <span className="plan-price"><strong>{planDetails?.price}</strong> Rs/month</span>
                    <button className="change-plan" onClick={changePlan}>
                        <img src='/images/dashboard/pen.png' alt="Change plan" className="change-icon" />
                        Change plan
                    </button>
                </div>
            </div>

            <div className="plan-notice">
                <i className="icon-info"></i>
                <p>
                    Kindly ensure that your wallet has at least {planDetails?.price} Rs for a monthly auto deduction on 1st of every month. Otherwise, membership will be suspended for 3 months.
                </p>
            </div>

            <button className="unsubscribe-button" onClick={handleOpenModal}>
                Unsubscribe from the plan
            </button>
            <UnsubscribeModal open={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirmModal} />
            <SuccessfulUnsubscriptionModal open={isSuccessfulUnsubscriptionModalOpen} onClose={handleSuccessfulUnubscriptionCloseModal} />
        </div>
    );
};

export default PlanCard;
