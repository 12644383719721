// jazzCashForm.js
export const submitJazzCashForm = (formData) => {
    const form = document.createElement('form');
    form.method = 'POST';
    const isProduction = process.env.REACT_APP_JAZZCASH_PRODUCTION;
    let baseUrl = 'https://sandbox.jazzcash.com.pk';
    if(isProduction === 1 || isProduction === "1"){
        baseUrl = 'https://payments.jazzcash.com.pk';
    }
    const url = baseUrl + '/WalletLinkingPortal/wallet/LinkWallet';
    form.action = url;
    // Create hidden input fields based on formData
    Object.keys(formData).forEach((key) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = formData[key];
        form.appendChild(input);
    });
    // Append the form to the body and submit it
    document.body.appendChild(form);
    form.submit();
};
