import { useLocation } from "react-router-dom";
import React, { useState/* , useContext  */} from 'react';
import { useNavigate } from 'react-router-dom';
import './payment.scss';
import { PAYMENT_METHODS } from '../../utils/constants';
// import PlanContext from '../../contexts/PlanContext';
import { useToast } from '../../components/ToastNotification/ToastNotification';
import EditIcon from '@mui/icons-material/Edit';  // Import MUI Edit icon
import { useAppDispatch } from '../../store';
import { prepareUserPayment } from "../../features/api.slice";
import SpinnerPage from "../../components/Spinner/Spinner";
const Payment = () => {
    const location = useLocation();
    const [selectedMethod, setSelectedMethod] = useState(PAYMENT_METHODS[0].name);
    const [isLoading, setIsLoading] = useState(false);
    const userSelectedPlan = location.state?.selectedUserPlan;
    const storedUserData = JSON.parse(localStorage.getItem('user'));
    const [phoneNumber, setPhoneNumber] = useState(storedUserData?.phone || '');
    const [isEditing, setIsEditing] = useState(false);  // For edit mode
    const showToast = useToast();
    const navigate = useNavigate();
    // const { setPaymentMethod } = useContext(PlanContext);

    const handleMethodChange = (event) => {
        setSelectedMethod(event.target.value);
        if (event.target.value === 'Easypaisa') {
            showToast('We will be launching with Easypaisa soon', 4000);
        }
    };  
    const dispatch = useAppDispatch();
    const handleSubmit = () => {
        setIsLoading(true);
       if (selectedMethod === 'JazzCash') {
            const payload = {
                phone: phoneNumber,
                gateway: "jazzcash",
                planId: userSelectedPlan?.id,
                showToast,
                navigate,
                setIsLoading
            }
            dispatch(prepareUserPayment(payload))
        } 
       if(selectedMethod === 'Credit or Debit card') {
            const payload = {
                phone: phoneNumber,
                gateway: "swich",
                planId: userSelectedPlan?.id,
                showToast,
                navigate,
                setIsLoading
            }
            dispatch(prepareUserPayment(payload))
        }
    };
    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };
    return (
        <div>
            <div className="payment-method-container">
                <h2>Save payment details</h2>
                <p className="description">
                    Confirm your '<strong>{userSelectedPlan?.title}</strong>' level subscription by providing payment details.
                </p>
                <p className="sub-description">
                    Contributions will be deducted automatically on the 1st of every month, with your selected monthly
                    contribution limit as maximum. No deduction will be made if no member needs treatment.
                </p>
                <div className="payment-options">
                    {PAYMENT_METHODS?.map((method) => (
                        <label
                            key={method.name}
                            className={`payment-option ${selectedMethod === method?.name ? 'selected' : ''}`}
                        >
                            <input
                                type="radio"
                                value={method.name}
                                disabled={isLoading}
                                checked={selectedMethod === method.name}
                                onChange={handleMethodChange}
                            />
                            <img src={method.icon} alt={method.name} />
                            <div className="payment-name">
                                <span>{method.name}</span>
                                {selectedMethod === 'JazzCash' && method?.name === 'JazzCash' && (
                                    <div className="payment-number-container">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="payment-number-input"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                onBlur={toggleEdit}
                                                disabled={isLoading}
                                                autoFocus
                                            />
                                        ) : (
                                            <span className="payment-number">
                                                {phoneNumber}
                                                <EditIcon
                                                    className="edit-icon"
                                                    onClick={toggleEdit}
                                                    fontSize="small"
                                                />
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </label>
                    ))}
                </div>
                <button
                    className={selectedMethod === 'Easypaisa' || isLoading ? 'disabled-button continue-button' : 'continue-button'}
                    disabled={selectedMethod === 'Easypaisa' || isLoading}
                    onClick={handleSubmit}
                >
                    {isLoading ? 'Processing' : `Continue with ${selectedMethod}`}
                </button>
            </div>
            <SpinnerPage loading={isLoading} />
        </div>
    );
};

export default Payment;
