import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import './Announcements.scss';
import formatDate from '../../utils/global';
import { useAppDispatch } from '../../store';
import { getAnnouncementsForUsersData } from '../../features/api.slice';

// Function to filter announcements based on current date
function getValidAnnouncements(announcements) {
    const currentDate = new Date();
    return announcements?.filter(announcement => {
        const startDate = new Date(announcement.start_date);
        const endDate = new Date(announcement.end_date);
        return currentDate >= startDate && currentDate <= endDate;
    });
};

function AnnouncementPage() {
    const dispatch = useAppDispatch();
    const [announcements, setAnnouncements] = React.useState([]);
    useEffect(() => {
        const payload = {
            setAnnouncements
        }
        dispatch(getAnnouncementsForUsersData(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const validAnnouncements = getValidAnnouncements(announcements.response);
    function handleDownload(url){
        window.open(url, '_blank');
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: '20px',
                backgroundColor: '#f5f5f5',
                minHeight: '100vh',
                overflowY: 'auto', // Allow scrolling on smaller screens
            }}
        >
            <div className="policy-header">
                <h2 className="header-title">Announcements</h2>
            </div>
            <div className="announcement-container">
                {validAnnouncements?.length ? (
                    validAnnouncements?.map((announcement) => (
                        <Card
                            key={announcement.id}
                            sx={{
                                borderRadius: '16px',
                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                                transition: 'all 0.3s ease-in-out',
                            }}
                            className='announcement-card'
                        >
                            <CardContent>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Typography variant="h6" color="textPrimary">
                                        {announcement.title}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" sx={{ flexGrow: 1 }}>
                                        {announcement.description}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        {/* Move the date to a more suitable position on mobile */}
                                        <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.875rem', display: { xs: 'none', sm: 'block' } }}>
                                            Announced on: {formatDate(announcement.start_date)}
                                        </Typography>
                                        {/* Download document button if documentLink is available */}
                                        {announcement?.photo_url_main && (
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={()=>{handleDownload(announcement?.photo_url_main)}}
                                                download
                                            >
                                                Download Document
                                            </Button>
                                        )}
                                    </div>
                                    {/* Date positioned below the message for mobile view */}
                                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.875rem', display: { xs: 'block', sm: 'none' } }}>
                                        Announced on: {formatDate(announcement?.startDate)}
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <Typography variant="h5" color="textSecondary">
                        No announcements available.
                    </Typography>
                )}
            </div>
        </Box>
    );
};

export default AnnouncementPage;
